import React from "react";

class Terms extends React.Component {
  render() {
    return (
      <div className="container pt-5">
        <h3 id="the-frij-io-terms-of-service">The Frij.io Terms of Service</h3>
        <p>Effective date: November 27, 2020</p>
        <h3 id="a-definitions">A. Definitions</h3>
        <p><strong>Short version:</strong> <em>We use these basic terms throughout the agreement, and they have specific meanings. You should know what we mean when we use each of the terms. There&#39;s not going to be a test on it, but it&#39;s still useful information.</em></p>
        <ol>
        <li>An &quot;Account&quot; represents your legal relationship with Frij.io. A “User Account” represents an individual User’s authorization to log in to and use the Service and serves as a User’s identity on Frij.io. </li>
        <li>The “Agreement” refers, collectively, to all the terms, conditions, notices contained or referenced in this document (the “Terms of Service” or the &quot;Terms&quot;) and all other operating rules, policies (including the Frij.io Privacy Statement, available at <a href="https://frij.io/privacy">frij.io/privacy</a>) and procedures that we may publish from time to time on the Website. </li>
        <li>&quot;Beta Previews&quot; mean software, services, or features identified as alpha, beta, preview, early access, or evaluation, or words or phrases with similar meanings.</li>
        <li>“Content” refers to content featured or displayed through the Website, including without limitation code, text, data, articles, images, photographs, graphics, software, applications, packages, designs, features, and other materials that are available on the Website or otherwise available through the Service. &quot;Content&quot; also includes Services. “User-Generated Content” is Content, written or otherwise, created or uploaded by our Users. &quot;Your Content&quot; is Content that you create or own.</li>
        <li>“Frij.io,” “We,” and “Us” refer to Frij.io, as well as our affiliates, directors, subsidiaries, contractors, licensors, officers, agents, and employees.</li>
        <li>The “Service” refers to the applications, software, products, and services provided by Frij.io, including any Beta Previews.</li>
        <li>“The User,” “You,” and “Your” refer to the individual person, company, or organization that has visited or is using the Website or Service; that accesses or uses any part of the Account; or that directs the use of the Account in the performance of its functions. A User must be at least 13 years of age.</li>
        <li>The “Website” refers to Frij.io’s website located at <a href="https://frij.io/">frij.io</a>, and all content, services, and products provided by Frij.io at or through the Website. It also refers to Frij.io-owned subdomains of frij.io, such as <a href="https://fridgemagnets.xyz/">fridgemagnets.xyz</a></li>
        </ol>
        <h3 id="b-account-terms">B. Account Terms</h3>
        <p><strong>Short version:</strong> <em>User Accounts have different administrative controls; a human must create your Account; you must be 13 or over; you must provide a valid email address; and you may not have more than one free Account. You alone are responsible for your Account and anything that happens while you are signed in to or using your Account. You are responsible for keeping your Account secure.</em></p>
        <h4 id="1-account-controls">1. Account Controls</h4>
        <p>Subject to these Terms, you retain ultimate administrative control over your User Account and the Content within it.</p>
        <h4 id="2-required-information">2. Required Information</h4>
        <p>You must provide a valid email address in order to complete the signup process. Any other information requested, such as your real name, is optional, unless you are accepting these terms on behalf of a legal entity (in which case we need more information about the legal entity) or if you opt for a paid account, in which case additional information will be necessary for billing purposes.</p>
        <h4 id="3-account-requirements">3. Account Requirements</h4>
        <p>We have a few simple rules for User Accounts on Frij.io&#39;s Service.</p>
        <ul>
        <li>You must be a human to create an Account. Accounts registered by &quot;bots&quot; or other automated methods are not permitted. We do permit machine accounts:</li>
        <li>A machine account is an Account set up by an individual human who accepts the Terms on behalf of the Account, provides a valid email address, and is responsible for its actions. A machine account is used exclusively for performing automated tasks. Multiple users may direct the actions of a machine account, but the owner of the Account is ultimately responsible for the machine&#39;s actions. You may maintain no more than one free machine account in addition to your free User Account.</li>
        <li>One person or legal entity may maintain no more than one free Account (if you choose to control a machine account as well, that&#39;s fine, but it can only be used for running a machine).</li>
        <li>You must be age 13 or older. While we are thrilled to see brilliant young coders get excited by learning to program, we must comply with United States law. Frij.io does not target our Service to children under 13, and we do not permit any Users under 13 on our Service. If we learn of any User under the age of 13, we will <a href="#g-cancellation-and-termination">terminate that User’s Account immediately</a>. If you are a resident of a country outside the United States, your country’s minimum age may be older; in such a case, you are responsible for complying with your country’s laws.</li>
        <li>Your login may only be used by one person — i.e., a single login may not be shared by multiple people.</li>
        </ul>
        <h4 id="4-user-account-security">4. User Account Security</h4>
        <p>You are responsible for keeping your Account secure while you use our Service. We offer tools such as 3rd party sign in to help you maintain your Account&#39;s security, but the content of your Account and its security are up to you.</p>
        <ul>
        <li>You are responsible for all content posted and activity that occurs under your Account (even when content is posted by others who have Accounts under your Account).</li>
        <li>You are responsible for maintaining the security of your Account and password. Frij.io cannot and will not be liable for any loss or damage from your failure to comply with this security obligation.</li>
        <li>You will promptly <a href="https://frij.io/contact">notify Frij.io</a> if you become aware of any unauthorized use of, or access to, our Service through your Account, including any unauthorized use of your password or Account.</li>
        </ul>
        <h4 id="5-additional-terms">5. Additional Terms</h4>
        <p>In some situations, third parties&#39; terms may apply to your use of Frij.io. Please be aware that while these Terms are our full agreement with you, other parties&#39; terms govern their relationships with you.</p>
        <h3 id="c-acceptable-use">C. Acceptable Use</h3>
        <p><strong>Short version:</strong> <em>Frij.io hosts a wide variety of collaborative projects from all over the world, and that collaboration only works when our users are able to work together in good faith. While using the service, you must follow the terms of this section, which include some restrictions on content you can post, conduct on the service, and other limitations. In short, be excellent to each other.</em></p>
        <p>Your use of the Website and Service must not violate any applicable laws, including copyright or trademark laws, export control or sanctions laws, or other laws in your jurisdiction. You are responsible for making sure that your use of the Service is in compliance with laws and any applicable regulations.</p>
        <h3 id="d-user-generated-content">D. User-Generated Content</h3>
        <p><strong>Short version:</strong> <em>You own content you create, but you allow us certain rights to it, so that we can display and share the content you post. You still have control over your content, and responsibility for it, and the rights you grant us are limited to those we need to provide the service. We have the right to remove content or close Accounts if we need to.</em></p>
        <h4 id="1-responsibility-for-user-generated-content">1. Responsibility for User-Generated Content</h4>
        <p>You may create or upload User-Generated Content while using the Service. You are solely responsible for the content of, and for any harm resulting from, any User-Generated Content that you post, upload, link to or otherwise make available via the Service, regardless of the form of that Content. We are not responsible for any public display or misuse of your User-Generated Content.</p>
        <h4 id="2-frij-io-may-remove-content">2. Frij.io May Remove Content</h4>
        <p>We have the right to refuse or remove any User-Generated Content that, in our sole discretion, violates any laws or Frij.io terms or policies. User-Generated Content displayed on Frij.io for mobile may be subject to mobile app stores&#39; additional terms.</p>
        <h4 id="3-ownership-of-content-right-to-post-and-license-grants">3. Ownership of Content, Right to Post, and License Grants</h4>
        <p>You retain ownership of and responsibility for Your Content. If you&#39;re posting anything you did not create yourself or do not own the rights to, you agree that you are responsible for any Content you post; that you will only submit Content that you have the right to post; and that you will fully comply with any third party licenses relating to Content you post.</p>
        <p>Because you retain ownership of and responsibility for Your Content, we need you to grant us — and other Frij.io Users — certain legal permissions, listed in Sections D.4 — D.7. These license grants apply to Your Content. If you upload Content that already comes with a license granting Frij.io the permissions we need to run our Service, no additional license is required. You understand that you will not receive any payment for any of the rights granted in Sections D.4 — D.7. The licenses you grant to us will end when you remove Your Content from our servers, unless other Users have forked it.</p>
        <h4 id="4-license-grant-to-us">4. License Grant to Us</h4>
        <p>We need the legal right to do things like host Your Content, publish it, and share it. You grant us and our legal successors the right to store, archive, parse, and display Your Content, and make incidental copies, as necessary to provide the Service, including improving the Service over time. This license includes the right to do things like copy it to our database and make backups; show it to you and other users; parse it into a search index or otherwise analyze it on our servers; share it with other users; and perform it, in case Your Content is something like music or video.</p>
        <p>This license does not grant Frij.io the right to sell Your Content. It also does not grant Frij.io the right to otherwise distribute or use Your Content outside of our provision of the Service, except that as part of the right to archive Your Content.</p>
        <h3 id="e-copyright-infringement-and-dmca-policy">E. Copyright Infringement and DMCA Policy</h3>
        <p>If you believe that content on our website violates your copyright or if you are a copyright owner and you believe that content on Frij.io violates your rights, please <a href="https://frij.io/contact">notify Frij.io</a>. There may be legal consequences for sending a false or frivolous takedown notice. Before sending a takedown request, you must consider legal uses such as fair use and licensed uses.</p>
        <p>We will terminate the Accounts of repeat infringers of this policy.</p>
        <h3 id="f-intellectual-property-notice">F. Intellectual Property Notice</h3>
        <p><strong>Short version:</strong> <em>We own the service and all of our content. In order for you to use our content, we give you certain rights to it, but you may only use our content in the way we have allowed.</em></p>
        <h4 id="1-frij-io-s-rights-to-content">1. Frij.io&#39;s Rights to Content</h4>
        <p>Frij.io and our licensors, vendors, agents, and/or our content providers retain ownership of all intellectual property rights of any kind related to the Website and Service. We reserve all rights that are not expressly granted to you under this Agreement or by law. The look and feel of the Website and Service is copyright © Frij.io, All rights reserved. You may not duplicate, copy, or reuse any portion of the HTML/CSS, Javascript, or visual design elements or concepts without express written permission from Frij.io.</p>
        <h4 id="2-frij-io-trademarks-and-logos">2. Frij.io Trademarks and Logos</h4>
        <p>If you’d like to use Frij.io’s trademarks, please <a href="https://frij.io/contact">contact Frij.io</a>.</p>
        <h4 id="3-license-to-frij-io-policies">3. License to Frij.io Policies</h4>
        <p>This Agreement is licensed under this <a href="https://creativecommons.org/publicdomain/zero/1.0/">Creative Commons Zero license</a>.</p>
        <h3 id="g-cancellation-and-termination">G. Cancellation and Termination</h3>
        <p><strong>Short version:</strong> <em>You may close your Account at any time. If you do, we&#39;ll treat your information responsibly.</em></p>
        <h4 id="1-account-cancellation">1. Account Cancellation</h4>
        <p>It is your responsibility to properly cancel your Account with Frij.io. You can cancel your Account at any time by going into your My Account modal in the global navigation bar at the top of the screen. The Account screen provides a simple, no questions asked cancellation link. We are not able to cancel Accounts in response to an email or phone request.</p>
        <h4 id="2-upon-cancellation">2. Upon Cancellation</h4>
        <p>We will retain and use your information as necessary to comply with our legal obligations, resolve disputes, and enforce our agreements, but barring legal requirements, we will delete your full profile and the Content of your posts within 90 days of cancellation or termination (though some information may remain in encrypted backups). This information can not be recovered once your Account is cancelled.</p>
        <p>Upon request, we will make a reasonable effort to provide an Account owner with a copy of your lawful, non-infringing Account contents after Account cancellation, termination, or downgrade. You must make this request within 90 days of cancellation, termination, or downgrade.</p>
        <h4 id="3-frij-io-may-terminate">3. Frij.io May Terminate</h4>
        <p>Frij.io has the right to suspend or terminate your access to all or any part of the Website at any time, with or without cause, with or without notice, effective immediately. Frij.io reserves the right to refuse service to anyone for any reason at any time.</p>
        <h4 id="4-survival">4. Survival</h4>
        <p>All provisions of this Agreement which, by their nature, should survive termination <em>will</em> survive termination — including, without limitation: ownership provisions, warranty disclaimers, indemnity, and limitations of liability.</p>
        <h3 id="h-communications-with-frij-io">H. Communications with Frij.io</h3>
        <p><strong>Short version:</strong> <em>We use email and other electronic means to stay in touch with our users.</em></p>
        <h4 id="1-electronic-communication-required">1. Electronic Communication Required</h4>
        <p>For contractual purposes, you (1) consent to receive communications from us in an electronic form via the email address you have submitted or via the Service; and (2) agree that all Terms of Service, agreements, notices, disclosures, and other communications that we provide to you electronically satisfy any legal requirement that those communications would satisfy if they were on paper. This section does not affect your non-waivable rights.</p>
        <h4 id="2-legal-notice-to-frij-io-must-be-in-writing">2. Legal Notice to Frij.io Must Be in Writing</h4>
        <p>Communications made through email or Frij.io Support&#39;s messaging system will not constitute legal notice to Frij.io or any of its officers, employees, agents or representatives in any situation where notice to Frij.io is required by contract or any law or regulation. Legal notice to Frij.io must be in writing.</p>
        <h4 id="3-no-phone-support">3. No Phone Support</h4>
        <p>Frij.io only offers support via email. We do not offer telephone support.</p>
        <h3 id="i-disclaimer-of-warranties">I. Disclaimer of Warranties</h3>
        <p><strong>Short version:</strong> <em>We provide our service as is, and we make no promises or guarantees about this service. Please read this section carefully; you should understand what to expect.</em></p>
        <p>Frij.io provides the Website and the Service “as is” and “as available,” without warranty of any kind. Without limiting this, we expressly disclaim all warranties, whether express, implied or statutory, regarding the Website and the Service including without limitation any warranty of merchantability, fitness for a particular purpose, title, security, accuracy and non-infringement.</p>
        <p>Frij.io does not warrant that the Service will meet your requirements; that the Service will be uninterrupted, timely, secure, or error-free; that the information provided through the Service is accurate, reliable or correct; that any defects or errors will be corrected; that the Service will be available at any particular time or location; or that the Service is free of viruses or other harmful components. You assume full responsibility and risk of loss resulting from your downloading and/or use of files, information, content or other material obtained from the Service.</p>
        <h3 id="j-limitation-of-liability">J. Limitation of Liability</h3>
        <p><strong>Short version:</strong> <em>We will not be liable for damages or losses arising from your use or inability to use the service or otherwise arising under this agreement. Please read this section carefully; it limits our obligations to you.</em></p>
        <p>You understand and agree that we will not be liable to you or any third party for any loss of profits, use, goodwill, or data, or for any incidental, indirect, special, consequential or exemplary damages, however arising, that result from</p>
        <ul>
        <li>the use, disclosure, or display of your User-Generated Content;</li>
        <li>your use or inability to use the Service;</li>
        <li>any modification, price change, suspension or discontinuance of the Service;</li>
        <li>the Service generally or the software or systems that make the Service available;</li>
        <li>unauthorized access to or alterations of your transmissions or data;</li>
        <li>statements or conduct of any third party on the Service;</li>
        <li>any other user interactions that you input or receive through your use of the Service; or</li>
        <li>any other matter relating to the Service.</li>
        </ul>
        <p>Our liability is limited whether or not we have been informed of the possibility of such damages, and even if a remedy set forth in this Agreement is found to have failed of its essential purpose. We will have no liability for any failure or delay due to matters beyond our reasonable control.</p>
        <h3 id="k-release-and-indemnification">K. Release and Indemnification</h3>
        <p><strong>Short version:</strong> <em>You are responsible for your use of the service. If you harm someone else or get into a dispute with someone else, we will not be involved.</em></p>
        <p>If you have a dispute with one or more Users, you agree to release Frij.io from any and all claims, demands and damages (actual and consequential) of every kind and nature, known and unknown, arising out of or in any way connected with such disputes.</p>
        <p>You agree to indemnify us, defend us, and hold us harmless from and against any and all claims, liabilities, and expenses, including attorneys’ fees, arising out of your use of the Website and the Service, including but not limited to your violation of this Agreement, provided that Frij.io (1) promptly gives you written notice of the claim, demand, suit or proceeding; (2) gives you sole control of the defense and settlement of the claim, demand, suit or proceeding (provided that you may not settle any claim, demand, suit or proceeding unless the settlement unconditionally releases Frij.io of all liability); and (3) provides to you all reasonable assistance, at your expense.</p>
        <h3 id="l-changes-to-these-terms">L. Changes to These Terms</h3>
        <p><strong>Short version:</strong> <em>We want our users to be informed of important changes to our terms, but some changes aren&#39;t that important — we don&#39;t want to bother you every time we fix a typo. So while we may modify this agreement at any time, we will notify users of any material changes and give you time to adjust to them.</em></p>
        <p>We reserve the right, at our sole discretion, to amend these Terms of Service at any time and will update these Terms of Service in the event of any such amendments. We will notify our Users of material changes to this Agreement, at least 5 days prior to the change taking effect by posting a notice on our Website or sending email to the primary email address specified in your Frij.io account. Customer&#39;s continued use of the Service after those 30 days constitutes agreement to those revisions of this Agreement. For any other modifications, your continued use of the Website constitutes agreement to our revisions of these Terms of Service.</p>
        <p>We reserve the right at any time and from time to time to modify or discontinue, temporarily or permanently, the Website (or any part of it) with or without notice.</p>
        <h3 id="m-miscellaneous">M. Miscellaneous</h3>
        <h4 id="1-governing-law">1. Governing Law</h4>
        <p>Except to the extent applicable law provides otherwise, this Agreement between you and Frij.io and any access to or use of the Website or the Service are governed by the federal laws of the United States of America and the laws of the State of California, without regard to conflict of law provisions. You and Frij.io agree to submit to the exclusive jurisdiction and venue of the courts located in the City and County of San Francisco, California.</p>
        <h4 id="2-non-assignability">2. Non-Assignability</h4>
        <p>Frij.io may assign or delegate these Terms of Service and/or the <a href="https://frij.io/privacy">Frij.io Privacy Statement</a>, in whole or in part, to any person or entity at any time with or without your consent, including the license grant in Section D.4. You may not assign or delegate any rights or obligations under the Terms of Service or Privacy Statement without our prior written consent, and any unauthorized assignment and delegation by you is void.</p>
        <h4 id="3-section-headings-and-summaries">3. Section Headings and Summaries</h4>
        <p>Throughout this Agreement, each section includes titles and brief summaries of the following terms and conditions. These section titles and brief summaries are not legally binding.</p>
        <h4 id="4-severability-no-waiver-and-survival">4. Severability, No Waiver, and Survival</h4>
        <p>If any part of this Agreement is held invalid or unenforceable, that portion of the Agreement will be construed to reflect the parties’ original intent. The remaining portions will remain in full force and effect. Any failure on the part of Frij.io to enforce any provision of this Agreement will not be considered a waiver of our right to enforce such provision. Our rights under this Agreement will survive any termination of this Agreement.</p>
        <h4 id="5-amendments-complete-agreement">5. Amendments; Complete Agreement</h4>
        <p>This Agreement may only be modified by a written amendment signed by an authorized representative of Frij.io, or by the posting by Frij.io of a revised version in accordance with <a href="#l-changes-to-these-terms">Section L. Changes to These Terms</a>. These Terms of Service, together with the Frij.io Privacy Statement, represent the complete and exclusive statement of the agreement between you and us. This Agreement supersedes any proposal or prior agreement oral or written, and any other communications between you and Frij.io relating to the subject matter of these terms including any confidentiality or nondisclosure agreements.</p>
        <h4 id="6-questions">6. Questions</h4>
        <p>Questions about the Terms of Service? <a href="https://frij.io/contact">Contact us</a>.</p>
      </div>
    );
  }
}

export default Terms;