import React from "react";
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Button from "react-bootstrap/Button"
import {auth} from '../services/firebase';
import FullPageLoader from "./FullPageLoader";

class VerifyEmail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      heading: "",
      sub_heading: "",
      button: ""
    };
  }

  componentDidMount = () => {
    auth().applyActionCode(this.props.actionCode).then((resp) => {
      this.setState({
        loading: false,
        heading: "Welcome to Fridge Magnets!",
        sub_heading: "Your account is ready", 
        button: "Get Started!"
      });
    }).catch((error) => {
      this.setState({
        loading: false,
        heading: "Oops, something went wrong...",
        sub_heading: "Double check the verification link in your email", 
        button: "Back to Main Page"
      });
    });
  }

  enterSite = async (event) => {
    window.location.href = '/';
  }

  render() {
    return (
      <div>
        { this.state.loading ?
          <FullPageLoader /> :
          <Container className="container__verify_email px-5">
            <Row>
              <Col md={{ span: 8, offset: 2 }}>
                <div>
                  <h1 className="mt-5">{this.state.heading}</h1>
                  <p>{this.state.sub_heading}</p>
                  <hr></hr>
                  <Button className="mt-2 mb-5 px-4" onClick={this.enterSite}>{this.state.button}</Button>
                </div>
              </Col>
            </Row>
          </Container>
        }
      </div>
    );
  }
}

export default VerifyEmail;