import firebase from 'firebase';

const firebaseConfig = {
  apiKey: "AIzaSyBf80JenSllN6Z81YrzSk4jdXm8xZ8MduY",
  authDomain: "fridgemagnets-xyz.firebaseapp.com",  
  databaseURL: "https://fridgemagnets-xyz.firebaseio.com",
  projectId: "fridgemagnets-xyz",
  storageBucket: "fridgemagnets-xyz.appspot.com",
  messagingSenderId: "821346814429",
  appId: "1:821346814429:web:f84e90098556ff3c0ace34",
  measurementId: "G-TVZK5B1SRZ"
};

firebase.initializeApp(firebaseConfig);
export const auth = firebase.auth;
export const db = firebase.firestore();
export const functions = firebase.functions();
export const storage = firebase.storage();
export const storage_ref = firebase.storage().ref();