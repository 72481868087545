import React from "react";
import Button from "react-bootstrap/Button"
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import InputGroup from "react-bootstrap/InputGroup";
import { signUp, signIn, signInWithGoogle, sendEmailVerification, forgotPassword } from '../helpers/auth';


class SignInModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      sign_in_active: true,
      email_verification_prompt: false,
      email_resent: false,
      forgot_password: false,
      validated: false,
      username_invalid_flag: false, 
      waiting_for_sign_up: false,
      error: null, 
      email: "",
      username: "",
      password: ""
      //referral: "" // REFERRAL CODE
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.setSignInActive = this.setSignInActive.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.show && this.props.show) {
      this.setState({email_verification_prompt: false});
    }
  }

  handleChange(event) {
    var temp_username_invalid_flag = this.state.username_invalid_flag; 
    if ((event.target.name) === "username") {
      temp_username_invalid_flag = false; 
    }
    this.setState({
      [event.target.name]: event.target.value, 
      username_invalid_flag: temp_username_invalid_flag
    });
  }

  setSignInActive(new_sign_in_state) {
    this.setState(state => ({
      sign_in_active: new_sign_in_state
    }));
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    this.setState({ error: '' });
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    }
    this.setState({validated: true});
    
    try {
      if (this.state.sign_in_active) {
        if (this.state.forgot_password) {
          forgotPassword(this.state.email);
          this.handleCloseModal();
        }
        else {
          this.setState({waiting_for_sign_up: true});
          await signIn(this.state.email, this.state.password);
          this.setState({waiting_for_sign_up: false});
          if (this.props.authenticated && !this.props.email_verified) {
            this.setState({email_verification_prompt: true});
          }
          else {
            this.handleCloseModal();
          }
        }
      }
      else if (!this.state.waiting_for_sign_up) {
        try {
          this.setState({waiting_for_sign_up: true});
          await signUp(this.state.email, this.state.password, this.state.username);//, this.state.referral); // REFERRAL CODE
          this.setState({
            email_verification_prompt: true, 
            waiting_for_sign_up: false 
          });
        } catch (error) {
          this.setState({ 
            username_invalid_flag: true, 
            waiting_for_sign_up: false 
          });
        }
      }
    } catch (error) {
      this.setState({waiting_for_sign_up: false});
      this.setState({ error: error.message });
    }
  }

  handleCloseModal = () => {
    this.setState({ 
      email_resent: false, 
      password: "",
      forgot_password: false
    });
    this.props.toggleModal();
    if (this.state.email_verification_prompt) {
      window.location.reload(false);
    }
  }

  handleResendVerificationEmail = () => {
    if (!this.state.email_resent) {
      sendEmailVerification();
      this.setState({ email_resent: true });
    }
  }

  handleSignInWithGoogle = () => {
    signInWithGoogle();
  }

  handleForgotPassword = () => {
    this.setState({ forgot_password: true });
  }

  render() {
    return (
      <Modal show={this.props.show} onHide={this.handleCloseModal}>
        <Modal.Header className="sign-in__modal__header" closeButton></Modal.Header>
        {!this.state.email_verification_prompt && <span><Modal.Body className="sign-in__modal__body">
          {!this.state.forgot_password && 
            <Row className="sign-in__nav pb-3">
              <Col 
                className={this.state.sign_in_active ? "sign-in__nav__item--active": "sign-in__nav__item--inactive"} 
                onClick={this.setSignInActive.bind(this, true)}
              >
                <span>Sign In</span>
              </Col>
              <Col 
                className={!this.state.sign_in_active ? "sign-in__nav__item--active": "sign-in__nav__item--inactive"} 
                onClick={this.setSignInActive.bind(this, false)}
              >
                <span>Sign Up</span>
              </Col>
            </Row>
          }
          <Form noValidate validated={this.state.validated} className="sign-in__form" onSubmit={this.handleSubmit} >
            {/* REFERRAL */}
            {/*{!this.state.sign_in_active && ( 
              <InputGroup className="sign-in__form__input">
                <Form.Control
                  className={this.state.username_invalid_flag ? "is-invalid" : ""}
                  type="text"
                  name="referral"
                  placeholder="Referral Code"
                  onChange={this.handleChange}
                  value={this.state.referral}
                  controlId="validation_referral"
                  required
                />
              </InputGroup> 
            )} */}
            {/* REFERRAL */}
            {!this.state.sign_in_active && (  
              <OverlayTrigger overlay={<Tooltip id="tooltip-disabled tooltip-bottom">Your username is how other Fridge Magnets users will see you.</Tooltip>}>
              <InputGroup className="sign-in__form__input">
                <InputGroup.Prepend className="remove-radius--right">
                  <InputGroup.Text id="inputGroupPrepend">@</InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  className={this.state.username_invalid_flag ? "remove-radius--left is-invalid" : "remove-radius--left"}
                  type="text"
                  name="username"
                  placeholder="Username"
                  onChange={this.handleChange}
                  value={this.state.username}
                  controlId="validation_username"
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Oops! This username or email was already taken.
                </Form.Control.Feedback>
              </InputGroup>
              </OverlayTrigger>
            )}
            <InputGroup className="sign-in__form__input">
              <Form.Control
                type="email"
                name="email"
                placeholder="Email"
                onChange={this.handleChange}
                value={this.state.email}
                required
              />
              <Form.Control.Feedback type="invalid">
                Please enter a valid email.
              </Form.Control.Feedback>
            </InputGroup>
            {!this.state.forgot_password && 
              <InputGroup className="sign-in__form__input">
                <Form.Control
                  type="password"
                  name="password"
                  placeholder="Password"
                  onChange={this.handleChange}
                  value={this.state.password}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Please enter a password.
                </Form.Control.Feedback>
              </InputGroup>
            } 
            {this.state.waiting_for_sign_up ?
              <div className="mb-3 mt-2 d-flex justify-content-center ">
                <div className="spinner-border sign-in__loader" role="status"></div>
              </div> :
              <div className="sign-in__form__submit">
                {this.state.error ? <p className="sign-in__form__error-text">{this.state.error}</p> : null}
                {this.state.forgot_password ? 
                  <Button className="sign-in__btn" block type="submit">Send Password Reset Link</Button> :
                  <Button className="sign-in__btn" block type="submit">{this.state.sign_in_active ? "Sign In" : "Sign Up"}</Button>
                }
              </div>
            }
            {this.state.sign_in_active && !this.state.forgot_password && <p className="pt-3 sign-in__forgot-password" onClick={this.handleForgotPassword}>Forgot Password?</p>}
          </Form>
        </Modal.Body>
        <Modal.Footer className="sign-in__modal__footer">
            {!this.state.forgot_password && <Button block className="sign-in__btn sign-in__btn--secondary" onClick={this.handleSignInWithGoogle}>Sign in with Google</Button>}
        </Modal.Footer></span>}
        {this.state.email_verification_prompt && 
          <div className="pt-4 pb-5 sign-in__email_verificaiton">
            <h3><b>Verify your email address</b></h3> 
            <i class="fas fa-envelope py-2 fa-4x"></i>
            <p className="px-3">We've sent a verification email to {this.state.email}. Please check your inbox for a link.</p>
            <Button block className="sign-in__btn mt-3" onClick={this.handleCloseModal}>Close</Button>
            {this.state.email_resent ?
              <Button block className="disabled sign-in__btn sign-in__btn--secondary mb-3" onClick={this.handleResendVerificationEmail}>Email Sent!</Button> : 
              <Button block className="sign-in__btn sign-in__btn--secondary mb-3" onClick={this.handleResendVerificationEmail}>Resend Link</Button> 
            }
          </div>
        }
        {!this.state.sign_in_active && <p className="px-5 sign-in__legal">By clicking Sign Up or Sign in with Google, you agree to our <a href= "/terms">Terms of Service</a></p>}  
      </Modal>
    );
  }
}

export default SignInModal;