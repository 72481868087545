import React from "react";
import Button from "react-bootstrap/Button"
import Container from "react-bootstrap/Container";
import Modal from "react-bootstrap/Modal";
import ProgressBar from "react-bootstrap/ProgressBar";

class FridgeNavbarMyAccountModal extends React.Component {

  constructor(props) {
    super(props);
    this.state = { 
      panel_num: 1
    };
  }
  
  handleNext = () => {
    this.setState({
      panel_num: this.state.panel_num + 1
    });
  }

  handleClose = () => {
    this.setState({
      panel_num: 1
    });
    this.props.closeModal();
  }

  
  render() {
    return (
      <Modal className="my-account__modal welcome-modal" size="xl" show={this.props.show} onHide={this.handleClose}>
        {this.state.panel_num === 1 && <Modal.Body className="welcome-modal__body pb-0 px-4 pt-4">
          {/* <h1>Welcome to frij.io!</h1> */}
          {/* <h2>frij.io brings fridge magnet poetry to the internet! Compete for top position on the leaderboard using today's word bank</h2> */}
          <div className="welcome-modal__img-container--no-heading p-3">
            <img  
              className="welcome-modal__gif"
              src={require("../img/welcome.png")}
            ></img>
          </div>
          <p></p>
        </Modal.Body> }
        {this.state.panel_num === 2 && <Modal.Body className="welcome-modal__body pb-0 px-4 pt-4">
          <h1>Make a Post</h1>
          <h2>Making a post is easy! Simply drag words around in the word bank and add them to the composer.</h2>
          <div className="welcome-modal__img-container p-3">
            <img  
              className="welcome-modal__gif--secondary"
              src={require("../img/compose.gif")}
            ></img>
          </div>
          <p></p>
        </Modal.Body> }
        {this.state.panel_num === 3 && <Modal.Body className="welcome-modal__body pb-0 px-4 pt-4">
          <h1>Upvote the Best Posts</h1>
          <h2>Push the best posts up to the top of the leaderboard.</h2>
          <div className="welcome-modal__img-container p-3">
            <img  
              className="welcome-modal__gif--secondary"
              src={require("../img/upvote.gif")}
            ></img>
          </div>
          <p></p>
        </Modal.Body> }
        {this.state.panel_num === 4 && <Modal.Body className="welcome-modal__body pb-0 px-4 pt-4">
          <h1>Collect Frij Cred</h1>
          <h2>You'll earn Frij Cred every time you make a post or hit upvote on the leaderboard</h2>
          <div className="welcome-modal__img-container p-3">
            <img  
              className="welcome-modal__gif--secondary"
              src={require("../img/frijcred.gif")}
            ></img>
          </div>
          <p></p>
        </Modal.Body> }
        <Container className="welcome-modal__progress-bar-container"><ProgressBar className="mb-4" now={this.state.panel_num*100/4} /></Container>
        <Modal.Footer className="my-account__modal__footer pt-0 pb-4">
          {this.state.panel_num < 4 && <Button block className="welcome-modal__btn" onClick={this.handleNext}>Next</Button>}
          {this.state.panel_num === 4 && <Button block className="welcome-modal__btn" onClick={this.handleClose}>Get Started</Button>}
        </Modal.Footer>
      </Modal>
    );
  }
}

export default FridgeNavbarMyAccountModal;