import React from "react";
import Sentence from "./Sentence";
import LeaderboardTileComment from "./LeaderboardTileComment";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import {mapScoreToLevel, truncateTailingZeros} from '../helpers/functions';
import { functions } from '../services/firebase';
import {BLACKLIST_REGEX} from '../helpers/constants';

var ReactDOM = require('react-dom');

class LeaderboardTile extends React.Component {

  constructor(props) {
    super(props);
    this.state = { 
      show_comments: false,
      comment_text: "",
      comment_input_ref: React.createRef()
    };
  }
  
  toggleComments = () => {
    this.setState({
      show_comments: !this.state.show_comments
    });
    setTimeout(() => {
      if (this.state.show_comments && this.state.comment_input_ref.current != null) {
        (this.state.comment_input_ref.current).focus();
        (this.state.comment_input_ref.current).select();
      }
    },100);
  }

  handleCommentChange = (event) => {
    this.setState({
      comment_text: event.target.value.slice(0,240).replace(BLACKLIST_REGEX, "")
    });
  }

  handlePostComment = (event) => {
    event.preventDefault();
    if (this.props.logged_in) {
      this.props.postComment(this.props.tile.order, this.props.tile.post_id, this.state.comment_text);
      this.setState({
        comment_text: ""
      });
    }
    else {
      this.props.toggleSignInModal()
    }
  }

  handleLoadMoreComments = (event) => {
    event.preventDefault();
    this.props.loadMoreComments(this.props.tile.order, this.props.tile.post_id);
  }


  getTimeString = () => {
    const elapstedHours = (Date.now() - this.props.tile.time.toMillis()) / 3600000;
    if (elapstedHours > 24) {
      return Math.round( elapstedHours / 24 ) + "d";
    } 
    return Math.round(elapstedHours) + "h";
  }

  setVote = async (new_vote) => {
    if (this.props.logged_in) {
      // Update the graphics
      let vote_client_side_update = new_vote;
      if (vote_client_side_update === this.props.tile.vote_status) {
        vote_client_side_update = 0;
      }
      let vote_delta = vote_client_side_update - this.props.tile.vote_status;
      this.props.updateVoteCount(this.props.tile.order, (vote_delta) );
      this.props.updateVoteLog(this.props.tile.post_id, vote_client_side_update);
      this.props.updateClientScore(vote_delta);
      // Update database
      try {
        var setVoteFirebase = functions.httpsCallable('setVote');
        var vote_response = setVoteFirebase({
          post_id: this.props.tile.post_id, 
          vote: new_vote,
        }).catch(console.error);
        // this.setState({
        //   vote_count: vote_ressponse.data.vote_count
        // });
      } catch (error) {
        console.log("Error setting vote: ", error);
      }
    } else {
      this.props.toggleSignInModal();
    }
  }

  render() {
    const comment_list = this.props.tile.comments.map((comment) =>
      <LeaderboardTileComment comment_data = {comment}/>
    );
    return (
      <Container className="leaderboard__tile">
        <Row className="leaderboard__tile__main px-2 py-2">
          <Col className="leaderboard__tile__everything-but-votes">
            <Row className="leaderboard__tile__title mb-2 pt-1">
              <div onClick={() => this.props.showUserDetails(this.props.tile.order)} className="leaderboard__tile__handle pr-0">@<b>{this.props.tile.username}</b></div> 
              <Col className="col-sm-auto leaderboard__tile__score">
                <span><i className={"fas fa-circle pr-1 user__level-" + mapScoreToLevel(this.props.tile.user_score)}></i>{this.props.tile.user_score.toLocaleString()}</span>
              </Col>
            </Row>
            <Sentence sentence={this.props.tile.sentence} static={true}/>
            <Row className="leaderboard__tile__footer mt-3">
              <Col xs={6}>{this.getTimeString()} ago</Col>
              <Col 
                xs={6} 
                className="leaderboard__tile__comment-button" 
                key={this.props.tile.post_id} 
                onClick={() => this.toggleComments()}
              > 
                {this.props.tile.comments_count === "error" ? 
                  <span>comments<span className="leaderboard__tile__comment__caret">▼</span></span> : 
                  <span>{this.props.tile.comments_count} comment{this.props.tile.comments_count===1 ? "": "s"} <span className="leaderboard__tile__comment__caret">▼</span></span>
                }
                {/* <i class="fas fa-caret-down fa-lg pl-2"></i> */}
              </Col>
            </Row>
          </Col>
          <Col className="leaderboard__tile__votes px-0 text-center">
            <span>
              <i 
                className={this.props.tile.vote_status===1 ? "fas fa-chevron-up leaderboard__tile__vote--active": "fas fa-chevron-up leaderboard__tile__vote--inactive"} 
                onClick={this.setVote.bind(this, 1)}
              ></i>
              <div> {truncateTailingZeros(this.props.tile.votes)} </div> 
              <i 
                className={this.props.tile.vote_status===-1 ? "fas fa-chevron-down leaderboard__tile__vote--active": "fas fa-chevron-down leaderboard__tile__vote--inactive"} 
                onClick={this.setVote.bind(this, -1)}
              ></i>
            </span>
          </Col>
        </Row>
        {this.state.show_comments && <div className="leaderboard__tile__comments p-2 mt-2">
          <Container>
            {comment_list}
            {this.props.tile.comments_load_more && !this.props.tile.comments_loading && <Row>
              <p onClick={this.handleLoadMoreComments} className="leaderboard__tile__comments__load-more-button">load more comments...</p>
            </Row>}
            {this.props.tile.comments_loading && <Row className="mb-3">
              <div className="spinner-border magnets__post-loader" role="status"></div>
            </Row>}
          </Container>
          <Form onSubmit={this.handlePostComment}>
            <Row className="mb-2">
              <Col className="pr-0">
                <Form.Control 
                  className="remove-radius--right"
                  type="comment" 
                  placeholder="comment" 
                  ref = {this.state.comment_input_ref}
                  value={this.state.comment_text}
                  onChange={this.handleCommentChange}
                />
              </Col>
              <Col xs="auto" className="pl-0">
                <Button className="leaderboard__tile__comments__button" type="submit"><i class="fas fa-share"></i></Button>
              </Col>
            </Row>
          </Form>
        </div>}
      </Container>
    );
  }
}

export default LeaderboardTile;

// Vote button truth table: 
// input  curr  new 
// 1      1      0
// 1      0      1
// 1      -1     1
// -1     1      -1
// -1     0      -1
// -1     -1     0