import React from "react";
import Col from "react-bootstrap/Col"
import {mapScoreToLevel} from '../helpers/functions'
import { LEVEL_NAMES} from "../helpers/constants"
import { storage, auth } from '../services/firebase';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

class UserStatsModalInfo extends React.Component {
  constructor() {
    super();
    this.state = {
      hide_user_info: false // (window.innerWidth < HIDE_USER_INFO_WIDTH),
    };
    window.addEventListener('resize', this.handleResize);
  }

  handleUpload = async (e) => {
    const file = e.target.files[0];
    const path = 'avatars/' + auth().currentUser.uid;
    const avatarRef = storage.ref().child(path);
    await avatarRef.put(file);
    this.props.changeAvatar();
  }

  render() {
    return (
      <div class="d-flex justify-content-center pb-4">
        <div className="my-account__user__avatar__wrapper">
          <label for="avatar__file__input">
          <img  
            className="user-info__avatar"
            src={this.props.avatar_url}
          ></img>
          </label>
        </div>
        <Col xs={0}>
          <div className="my-account__user__handle">{this.props.fridge_handle}</div>
          <OverlayTrigger key={"bottom"} placement={"bottom"} overlay={<Tooltip id="tooltip-bottom"><b>Frij Creds</b> & <b>Frij Level</b></Tooltip>}>
            <div className="my-account__user__score">
              <i className={"fas fa-circle pr-1 user__level-" + mapScoreToLevel(this.props.score)}></i>
              {this.props.score.toLocaleString()} - <b>{LEVEL_NAMES[mapScoreToLevel(this.props.score)]}</b>
            </div>
          </OverlayTrigger>
        </Col>
      </div>
    );
  }
}

export default UserStatsModalInfo;