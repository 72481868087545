import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button"
import FridgeNavbarMyAccountUserInfo from "./FridgeNavbarMyAccountUserInfo"
import Modal from "react-bootstrap/Modal";
import { signOut, deleteAccount, resetPassword } from '../helpers/auth';
import { auth } from "firebase";
import Container from "react-bootstrap/Container";

class FridgeNavbarMyAccountModal extends React.Component {

  constructor(props) {
    super(props);
    this.state = { 
      reauthenticate_pw: false,
      password: "",
      wrong_pw: false,
      loading: false
    };
  }

  handleReauthenticate = async () => {
    console.log(auth().currentUser)
    var google_signup = false;
    for (var provider of auth().currentUser.providerData) {
      google_signup = google_signup || provider.providerId === "google.com"
    }
    if(!google_signup) {
      this.setState({reauthenticate_pw: true})
    }
    else {
      const provider = new auth.GoogleAuthProvider();
      var refreshed_credentials = await auth().signInWithPopup(provider);
      this.setState({loading: true});
      var auth_result = await auth().currentUser.reauthenticateWithCredential(refreshed_credentials.credential)
      if (auth_result) {
        await deleteAccount();
      }
      this.setState({loading: false});
    }
  }

  handleTypePassword = (event) => {
    this.setState({
      wrong_pw: false,
      password: event.target.value, 
    });
  }

  handleChangePassword = () => {
    resetPassword();
    this.props.toggleAccountModal();
  }

  reauthenticatePasswordAndDelete = async () => {
    this.setState({loading: true});
    var refreshed_credentials = auth.EmailAuthProvider.credential(auth().currentUser.email, this.state.password);
    var auth_result = await auth().currentUser.reauthenticateWithCredential(refreshed_credentials).catch((error) => {
      this.setState({wrong_pw: true, loading: false});
    });
    if (auth_result) {
      await deleteAccount();
    }
    this.setState({loading: false});
  }

  handleCloseModal = () => {
    this.setState({
      password: "",
      reauthenticate_pw: false
    });
    this.props.toggleAccountModal();
  }

  render() {
    return (
      <Modal className="my-account__modal" show={this.props.show} onHide={this.handleCloseModal}>
        <Modal.Header className="my-account__modal__header" closeButton></Modal.Header>
        <Modal.Body className="my-account__modal__body">
          <FridgeNavbarMyAccountUserInfo 
            changeAvatar={this.props.changeAvatar} 
            fridge_handle ={this.props.user.username}
            score = {this.props.user.score}
            avatar_url = {this.props.user.avatar}
          />
          <Button block className="my-account__btn my-account__btn--secondary" onClick={this.handleChangePassword}>Change Password</Button> 
          <Button block className="my-account__btn my-account__btn--secondary" onClick={signOut}>Sign Out</Button>
          {this.state.reauthenticate_pw ?
              <Container className="my-account__reauth-password">
                <Row className="justify-content-center">
                  <Col xs={8} className="mx-0 px-0">
                    <Form.Control 
                      className={this.state.wrong_pw ? "remove-radius--right my-account__reauth-password__input mx-0 pr-0 pl-3 is-invalid" : "remove-radius--right my-account__reauth-password__input mx-0 pr-0 pl-3"}
                      type="password" 
                      placeholder="Enter password" 
                      value={this.state.password}
                      onChange={this.handleTypePassword}
                    />
                    <Form.Control.Feedback type="invalid">
                      Oops! Password incorrect
                    </Form.Control.Feedback>
                  </Col>
                  <Col xs={4} className="mx-0 px-0">
                    <Button block className="bold my-account__btn--secondary remove-radius--left mx-0 px-2" onClick={this.reauthenticatePasswordAndDelete}>Delete</Button>
                  </Col>
                </Row>
              </Container> :
            <Button block className="my-account__btn my-account__btn--secondary" onClick={this.handleReauthenticate}>Delete Account</Button> 
          }
          {this.state.loading && <div className="mb-3 mt-2 d-flex justify-content-center ">
            <div className="spinner-border sign-in__loader" role="status"></div>
          </div>}
        </Modal.Body>
        <Modal.Footer className="my-account__modal__footer">
          <Button block className="my-account__btn" onClick={this.props.toggleAccountModal}>Done</Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default FridgeNavbarMyAccountModal;