import React from 'react';
import Main from "./Main";
import UserManagement from "./UserManagement";
import Contact from "./components/Contact";
import Terms from "./components/Terms";
import Privacy from "./components/Privacy";
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

function App() {
  return (
    <Router>
      <Switch>        
        <Route path="/usermgmt" component={UserManagement} />
        <Route path="/contact" component={Contact} />
        <Route path="/privacy" component={Privacy} />
        <Route path="/terms" component={Terms} />
        <Route path="/" component={Main} />
      </Switch>
    </Router>
  );
}

export default App;
