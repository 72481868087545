import React from 'react';
import Main from "./Main";
import VerifyEmail from "./components/VerifyEmail";
import ResetPassword from "./components/ResetPassword";
import {
  useLocation
} from "react-router-dom";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function UserManagement() {
  let query = useQuery();
  let CheckQueryParams = (props) => {
    console.log(query.get("mode"))
    if (query.get("mode")==="verifyEmail"){return <VerifyEmail actionCode={query.get("oobCode")}/>}
    if (query.get("mode")==="resetPassword"){return <ResetPassword actionCode={query.get("oobCode")}/>}
    return <Main/>
  }

  return (
    CheckQueryParams()
  );
}

