import React from "react";
import Sentence from "./Sentence"
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { functions } from '../services/firebase';

class LeaderboardTile extends React.Component {

  render() {
    return (
      <Row >
        <p><span className="mr-2"><b>@{this.props.comment_data.username}</b></span>{this.props.comment_data.comment}</p>
      </Row>
    );
  }
}

export default LeaderboardTile;

// Vote button truth table: 
// input  curr  new 
// 1      1      0
// 1      0      1
// 1      -1     1
// -1     1      -1
// -1     0      -1
// -1     -1     0