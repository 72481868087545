import React from "react";

class Word extends React.Component {
  render() {
    return (
      <div className={this.props.active ? "magnet__word magnet__word--active" : "magnet__word magnet__word--inactive"}>
        {this.props.word}
        {(this.props.adword_link != "") && <a target="_blank" href={this.props.adword_link}><div className="magnet__word__ad-link">+</div></a>}
      </div>
    );
  }
}

export default Word;