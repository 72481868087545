import React from "react";
import LeaderboardTile from "./LeaderboardTile"
import LeaderboardFilterBar from "./LeaderboardFilterBar"
import { auth, db, storage_ref } from '../services/firebase';
import LeaderboardTileLoader from "./LeaderboardTileLoader";
import UserStatsModal from "./UserStatsModal";
import { functions } from '../services/firebase';
import { useSwipeable, Swipeable } from 'react-swipeable'
import {SWIPE_RELOAD_EXECUTE_DELTA, COMMENT_SCORE_BONUS} from "../helpers/constants"

class UserSpecificFeed extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      tiles: [],
      post_query_snapshot: null,
      more_posts_to_load: false,
      loading: true,
      loading_bottom: false,
      loader_height: 0,
      leaderboard_inf_scroll_ref: React.createRef(),
      loader_heigh_scroll_offset: 0,
    };
  }

  componentDidMount() {
    this.loadTiles(false);
  }

  componentDidUpdate(prevProps) {
    if (this.props.logged_in && !prevProps.logged_in) {
      this.updateVoteStatus()
    }
  }

  updateVoteStatus = () => {
    var temp_tiles = this.state.tiles;
    for (var index in temp_tiles) {
      temp_tiles[index].vote_status = this.props.user.vote_log[temp_tiles[index].post_id];
      if (temp_tiles[index].vote_status == undefined) {
        temp_tiles[index].vote_status = 0;
      }
    }
    this.setState({ tiles: temp_tiles });
  }

  loadTiles = async (is_pagination_request) => {
    // Configure loaders
    if(is_pagination_request) {
      this.setState({ loading_bottom: true });
    }
    else {
      this.setState({ loading: true });
    }

    // Try loading posts
    try {
      const doc_ref = db.collection("posts");
      var updated_tiles = [];
      var i = 0; 

      // Construct query
      var query = doc_ref.where('author_uid', '==', this.props.user_of_interest);
      if (this.props.filter.era !== "all") {
        query = query.where(`era.${this.props.filter.era}`, "==", true);
      }
      query = query.orderBy(this.props.filter.sort, "desc")
      if (is_pagination_request && this.state.post_query_snapshot != null) {
        query = query.startAfter(this.state.post_query_snapshot).limit(3);
      }
      else {
        query = query.limit(3);
      }
      let query_result = await query.get();
      let query_result_length = query_result.docs.length;
      this.setState({ post_query_snapshot: query_result.docs[query_result.docs.length-2] });
      if (query_result_length < 3) {
        this.setState({ more_posts_to_load: false });
      }
      else {
        this.setState({ more_posts_to_load: true });
        query_result_length -= 1;
      }

      // Iterate through results
      for (const post of query_result.docs.slice(0, query_result_length)) {
        try {
          var new_tile = post.data();
          // ------ Make Requests: ------
          // Author information 
          var post_author;
          var author_promise = db.collection("users").doc(post.data().author_uid).get()
            .then((result) => {
              post_author = result;
            });
          // Comment information 
          const comments_query = doc_ref.doc(post.id).collection("comments").orderBy("time").limit(11);
          var comments_query_result
          var comment_promise = comments_query.get()
            .then((result) => {
              comments_query_result = result;
            });
          // Comment count
          var getCommentCount = functions.httpsCallable('getCount');
          var comment_count_response;
          var comment_count_promise = getCommentCount({
            post_id: post.id, 
            counter_name: "comments"
          }).then((result) => {
            comment_count_response = result;
          }).catch( (error) => {
            console.log("Error getting count: ", error);
            comment_count_response = undefined;
          });

          // ------ Pase Data: ------
          await Promise.all([author_promise, comment_promise, comment_count_promise]);
          // Author information
          new_tile.user_score = post_author.data().score;
          new_tile.username = post_author.data().username;
          new_tile.user_data = post_author.data();
          new_tile.user_id = post.data().author_uid;
          // Avatar information
          if (new_tile.user_data.avatar === "default" || new_tile.user_data.avatar.length < 100) {
            new_tile.user_data.avatar = require("../img/default_avatar.png");
          }
          // Comment information 
          new_tile.comment_snapshot = comments_query_result.docs[comments_query_result.docs.length-2];
          new_tile.comments_loading = false;
          let new_comments = comments_query_result.docs.map((comment) => comment.data());
          // Comment count
          if (comment_count_response === undefined) {
            new_tile.comments_count = "error"
          } else {
            new_tile.comments_count = comment_count_response.data.count
          }
          // Check if there are still more commnets to paginate
          if (comments_query_result.size < 11) {
            new_tile.comments_load_more = false;
            new_tile.comments = new_comments;
          }
          else {
            new_tile.comments_load_more = true;
            new_tile.comments = new_comments.slice(0, 10);
          }
          
          // Upvote/downvote information 
          if(this.props.logged_in) {
            new_tile.vote_status = this.props.user.vote_log[post.id];
            if (new_tile.vote_status == undefined) {
              new_tile.vote_status = 0;
            }
          } else {
            new_tile.vote_status = 0;
          }
          // Post ID and order in array
          new_tile.post_id = post.id;
          new_tile.order = i + (is_pagination_request ? this.state.tiles.length: 0);
          updated_tiles[i] = new_tile;
          i++; 
        } catch (error) {
          console.log("Error loading tile #", i, ": ", error)
        }
      }
      if (is_pagination_request) {
        this.setState({ tiles: this.state.tiles.concat(updated_tiles) });
      }
      else {
        this.setState({ tiles: updated_tiles });
      }
    } catch (error) {
      console.log("Error getting documents: ", error);
    }
    this.setState({ 
      loading: false, 
      loading_bottom: false
    });
  }

  load_more_tiles = async () => {

  }

  postComment = async (post_index, post_id, comment_text) => {
    try {
      var createComment = functions.httpsCallable('createComment');
      var comment_response = createComment({
        post_id: post_id,
        comment: comment_text
      }).catch(console.error);
      let new_comment = {
        comment: comment_text, 
        time: new Date(),
        uid: auth().currentUser.uid,
        username: this.props.user.username
      };
      let temp_tiles = this.state.tiles;
      temp_tiles[post_index].comments.push(new_comment);
      temp_tiles[post_index].comments_count += 1; 
      this.props.updateClientScore(COMMENT_SCORE_BONUS);
      this.setState({ tiles: temp_tiles });
    } catch (error) {
      console.log("Error getting documents: ", error);
    }
  }

  loadMoreComments = async (post_index, post_id) => {
    try {
      let temp_tiles = this.state.tiles;
      // Activate comment loader
      temp_tiles[post_index].comments_loading = true;
      this.setState({ tiles: temp_tiles });
      // Query for new commnets
      const doc_ref = db.collection("posts");
      const comments_query = doc_ref.doc(post_id)
                                    .collection("comments")
                                    .orderBy("time")
                                    .startAfter(temp_tiles[post_index].comment_snapshot)
                                    .limit(11);
      let comments_query_result = await comments_query.get();
      let new_comments = comments_query_result.docs.map((comment) => comment.data());
      // Check if there are still more commnets to paginate
      if (comments_query_result.size < 11) {
        temp_tiles[post_index].comments_load_more = false;
        temp_tiles[post_index].comments = temp_tiles[post_index].comments.concat(new_comments)
      }
      else {
        temp_tiles[post_index].comments = temp_tiles[post_index].comments.concat(new_comments.slice(0, 10));
      }
      // Set cursor for next call
      temp_tiles[post_index].comment_snapshot = comments_query_result.docs[comments_query_result.docs.length-2];
      // Toggle loader
      temp_tiles[post_index].comments_loading = false;
      this.setState({ tiles: temp_tiles });
    } catch (error) {
      console.log("Error loading more comments: ", error);
    }
  }

  checkOrder = (index, delta, temp_tiles) => {
    if (delta > 0 && index != 0 && this.props.filter.sort == "votes") {
      if (temp_tiles[index].votes > temp_tiles[index-1].votes) {
        let temp_tile = temp_tiles[index-1];
        temp_tiles[index-1] = temp_tiles[index]
        temp_tiles[index-1].order = index-1;
        temp_tiles[index] = temp_tile;
        temp_tiles[index].order = index;
        this.checkOrder(index-1, delta, temp_tiles)
      }
    } else if (delta < 0 && index != (temp_tiles.length-1) && this.props.filter.sort == "votes") {
      if (temp_tiles[index].votes < temp_tiles[index+1].votes) {
        let temp_tile = temp_tiles[index+1];
        temp_tiles[index+1] = temp_tiles[index]
        temp_tiles[index+1].order = index+1;
        temp_tiles[index] = temp_tile;
        temp_tiles[index].order = index;
        this.checkOrder(index+1, delta, temp_tiles)
      }
    }
  }

  updateVoteCount = (index, delta) => {
    let temp_tiles = this.state.tiles;
    temp_tiles[index].votes += delta;
    this.checkOrder(index, delta, temp_tiles);
    this.setState({
      tiles: temp_tiles
    })
  }

  swipingHandler = (eventData) => {
    if (eventData.first) {
      this.setState({
        loader_heigh_scroll_offset: this.state.leaderboard_inf_scroll_ref.current.scrollTop
      });
    }
    if (this.state.leaderboard_inf_scroll_ref.current.scrollTop === 0  && eventData.deltaY < 0) {
      this.setState({
        loader_height: Math.floor(Math.abs(eventData.deltaY) * 0.5) - this.state.loader_heigh_scroll_offset
      });
    }
  }

  swipedHandler = (eventData) => {
    if (eventData.dir === "Down") {
      if (Math.floor(eventData.deltaY * 0.5) < -1*SWIPE_RELOAD_EXECUTE_DELTA) {
        this.props.updateFilter("reload", null)
      }
      this.setState({
        loader_height: 0
      });
    }
  }

  handleUpdateVoteLog = (post_id, state_value) => {
    this.props.updateVoteLog(post_id, state_value);
    this.updateVoteStatus();
  }

  handleScroll = (e) => {
    const near_bottom = e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight < 40;
    if (near_bottom && this.state.more_posts_to_load && !this.state.loading_bottom) { 
      this.loadTiles(true);
    }
  }

  render() { 
    const tile_list = this.state.tiles.map((tile) =>
      <LeaderboardTile 
        key = {tile.post_id} 
        tile = {tile} 
        date = {this.props.filter.date} 
        updateVoteLog = {this.handleUpdateVoteLog} 
        logged_in = {this.props.logged_in} 
        updateVoteCount = {this.updateVoteCount} 
        postComment = {this.postComment} 
        toggleSignInModal = {this.props.toggleSignInModal}
        loadMoreComments = {this.loadMoreComments}
        showUserDetails = {() => null}
        updateClientScore = {this.props.updateClientScore}
      />
    );
    return (
      <div className="mt-4 px-0 container">
        <Swipeable 
          onSwiping={(eventData) => null} //this.swipingHandler(eventData)} 
          onSwiped={(eventData) => null} //this.swipedHandler(eventData)}
        >
          <div className="user_specific_feed__infinite-scroll" onScroll={this.handleScroll} ref={this.state.leaderboard_inf_scroll_ref}>
            <LeaderboardTileLoader loading={this.state.loading} height={this.state.loader_height}/>
            {this.props.filter.following && this.props.user.following.length === 0 ? 
              <div className="text-center pt-3">
                <p className="bold">You're not following any frij friends yet...</p>
                <p>Click on any <i>@Username</i> to follow them</p>
              </div>:
              <span>{tile_list}</span>
            }
            {!this.state.loading_bottom && this.state.more_posts_to_load && !(this.props.filter.following && this.props.user.following.length === 0) && <span className="pl-3 leaderboar__infinite-scroll__load-more-button" onClick={()=>{this.loadTiles(true)}}><p className="text-center">load more posts...</p></span> }
            <LeaderboardTileLoader loading={this.state.loading_bottom} height={20}/>
          </div>
        </Swipeable>
      </div>
    );
  }
}

export default UserSpecificFeed;