import React from "react";

class Privacy extends React.Component {
  render() {
    return (
      <div className="container pt-5">
        <h2 id="frij-io-privacy-statement">Frij.io Privacy Statement</h2>
        <p>Effective date: November 27, 2020</p>
        <p>Thanks for entrusting Frij.io (“we”) to provide you with a great online fridge magnet experience. Holding on to your private information is a serious responsibility, and we want you to know how we&#39;re handling it.</p>
        <p>All capitalized terms have their definition in <a href="https://frij.io/terms">Frij.io’s Terms of Service</a>, unless otherwise noted here.</p>
        <h3 id="what-information-frij-io-collects">What information Frij.io collects</h3>
        <p>&quot;<strong>User Personal Information</strong>&quot; is any information about one of our Users which could, alone or together with other information, personally identify them or otherwise be reasonably linked or connected with them. Information such as a username and password, an email address, a real name, an Internet protocol (IP) address, and a photograph are examples of “User Personal Information.”</p>
        <p>User Personal Information does not include aggregated, non-personally identifying information that does not identify a User or cannot otherwise be reasonably linked or connected with them. We may use such aggregated, non-personally identifying information for research purposes and to operate, analyze, improve, and optimize our Website and Service.</p>
        <h4 id="information-users-provide-directly-to-frij-io">Information users provide directly to Frij.io</h4>
        <h5 id="registration-information">Registration information</h5>
        <p>We require some basic information at the time of account creation. When you create your own username and password, we ask you for a valid email address.</p>
        <h5 id="payment-information">Payment information</h5>
        <p>If you sign on to a paid Account with us or send funds through the Frij.io, we collect your full name, address, and credit card information or PayPal information. Please note, Frij.io does not process or store your credit card information or PayPal information, but our third-party payment processor does.</p>
        <h5 id="profile-information">Profile information</h5>
        <p>You may choose to give us more information for your Account profile, such as your full name or an avatar which may include a photograph. This information may include User Personal Information. Please note that your profile information may be visible to other Users of our Service.</p>
        <h4 id="information-frij-io-automatically-collects-from-your-use-of-the-service">Information Frij.io automatically collects from your use of the Service</h4>
        <h5 id="transactional-information">Transactional information</h5>
        <p>If you have a paid Account with us, we automatically collect certain information about your transactions on the Service, such as the date, time, and amount charged.</p>
        <h5 id="usage-information">Usage information</h5>
        <p>If you&#39;re accessing our Service or Website, we automatically collect the same basic information that most services collect, subject, where necessary, to your consent. This includes information about how you use the Service, such as the pages you view, the referring site, your IP address and session information, and the date and time of each request. This is information we collect from every visitor to the Website, whether they have an Account or not. This information may include User Personal information.</p>
        <h5 id="cookies-and-similar-technologies-information">Cookies and similar technologies information</h5>
        <p>As further described below, and subject, where applicable, to your consent, we automatically collect information from cookies and similar technologies (such as cookie ID and settings) to keep you logged in, to remember your preferences, and to identify you and your device.</p>
        <h5 id="device-information">Device information</h5>
        <p>We may collect certain information about your device, such as its IP address, browser or client application information, language preference, operating system and application version, device type and ID, and device model and manufacturer. This information may include User Personal information.</p>
        <h4 id="information-we-collect-from-third-parties">Information we collect from third parties</h4>
        <p>Frij.io may collect User Personal Information from third parties. For example, this may happen if you sign up for training or to receive information about Frij.io from one of our vendors, partners, or affiliates. Frij.io does not purchase User Personal Information from third-party data brokers.</p>
        <h3 id="what-information-frij-io-does-not-collect">What information Frij.io does not collect</h3>
        <p>We do not intentionally collect “<strong><a href="https://gdpr-info.eu/art-9-gdpr/">Sensitive Personal Information</a></strong>”, such as personal data revealing racial or ethnic origin, political opinions, religious or philosophical beliefs, or trade union membership, and the processing of genetic data, biometric data for the purpose of uniquely identifying a natural person, data concerning health or data concerning a natural person’s sex life or sexual orientation. If you choose to store any Sensitive Personal Information on our servers, you are responsible for complying with any regulatory controls regarding that data.</p>
        <p>If you are a child under the age of 13, you may not have an Account on Frij.io. Frij.io does not knowingly collect information from or direct any of our content specifically to children under 13. If we learn or have reason to suspect that you are a User who is under the age of 13, we will have to close your Account. We don&#39;t want to discourage you from learning to code, but those are the rules. Please see our <a href="https://frij.io/terms">Terms of Service</a> for information about Account termination. Different countries may have different minimum age limits, and if you are below the minimum age for providing consent for data collection in your country, you may not have an Account on Frij.io.</p>
        <h3 id="how-frij-io-uses-your-information">How Frij.io uses your information</h3>
        <ul>
        <li>We may use your information for the following purposes:</li>
        <li>We use your <a href="#registration-information">Registration Information</a> to create your account, and to provide you the Service.</li>
        <li>We use your <a href="#payment-information">Payment Information</a> to provide you with the Paid Account service, the Marketplace service, the Sponsors Program, or any other Frij.io paid service you request.</li>
        <li>We use your User Personal Information, specifically your username, to identify you on Frij.io.</li>
        <li>We use your <a href="#profile-information">Profile Information</a> to fill out your Account profile and to share that profile with other users if you ask us to.</li>
        <li>We use your email address to communicate with you, if you&#39;ve said that&#39;s okay, <strong>and only for the reasons you’ve said that’s okay</strong>. Please see our section on <a href="#how-we-communicate-with-you">email communication</a> for more information.</li>
        <li>We use User Personal Information to respond to support requests.</li>
        <li>We use User Personal Information and other data to make recommendations for you, such as to suggest projects you may want to follow or contribute to. We learn from your public behavior on Frij.io—such as the projects you star—to determine your coding interests, and we recommend similar projects. These recommendations are automated decisions, but they have no legal impact on your rights.</li>
        <li>We may use User Personal Information to invite you to take part in surveys, beta programs, or other research projects, subject, where necessary, to your consent .</li>
        <li>We use <a href="#usage-information">Usage Information</a> and <a href="#device-information">Device Information</a> to better understand how our Users use Frij.io and to improve our Website and Service.</li>
        <li>We may use your User Personal Information if it is necessary for security purposes or to investigate possible fraud or attempts to harm Frij.io or our Users.</li>
        <li>We may use your User Personal Information to comply with our legal obligations, protect our intellectual property, and enforce our <a href="https://frij.io/terms">Terms of Service</a>.</li>
        <li>We limit our use of your User Personal Information to the purposes listed in this Privacy Statement. If we need to use your User Personal Information for other purposes, we will ask your permission first. You can always see what information we have, how we&#39;re using it, and what permissions you have given us by <a href="https://frij.io/contact">contacting us directly</a>.</li>
        </ul>
        <h4 id="our-legal-bases-for-processing-information">Our legal bases for processing information</h4>
        <p>To the extent that our processing of your User Personal Information is subject to certain international laws (including, but not limited to, the European Union&#39;s General Data Protection Regulation (GDPR)), Frij.io is required to notify you about the legal basis on which we process User Personal Information. Frij.io processes User Personal Information on the following legal bases:</p>
        <ul>
        <li>Contract Performance:<ul>
        <li>When you create a Frij.io Account, you provide your <a href="#registration-information">Registration Information</a>. We require this information for you to enter into the Terms of Service agreement with us, and we process that information on the basis of performing that contract. We also process your username and email address on other legal bases, as described below.</li>
        <li>If you have a paid Account with us, we collect and process additional <a href="#payment-information">Payment Information</a> on the basis of performing that contract.</li>
        <li>When you buy or sell an application listed on our Marketplace or, when you send or receive funds through the Frij.io Sponsors Program, we process <a href="#payment-information">Payment Information</a> and additional elements in order to perform the contract that applies to those services.</li>
        </ul>
        </li>
        <li>Consent:<ul>
        <li>We rely on your consent to use your User Personal Information under the following circumstances: when you fill out the information in your user profile; when you decide to participate in a Frij.io training, research project, beta program, or survey; and for marketing purposes, where applicable. All of this User Personal Information is entirely optional, and you have the ability to access, modify, and delete it at any time. While you are not able to delete your email address entirely, you can make it private. You may withdraw your consent at any time.</li>
        </ul>
        </li>
        <li>Legitimate Interests:<ul>
        <li>Generally, the remainder of the processing of User Personal Information we perform is necessary for the purposes of our legitimate interest, for example, for legal compliance purposes, security purposes, or to maintain ongoing confidentiality, integrity, availability, and resilience of Frij.io’s systems, Website, and Service.</li>
        </ul>
        </li>
        <li>If you would like to request deletion of data we process on the basis of consent or if you object to our processing of personal information, please use our <a href="https://frij.io/contact">contact form</a>.</li>
        </ul>
        <h3 id="how-we-share-the-information-we-collect">How we share the information we collect</h3>
        <p>We may share your User Personal Information with third parties under one of the following circumstances:</p>
        <h4 id="with-your-consent">With your consent</h4>
        <p>We share your User Personal Information, if you consent, after letting you know what information will be shared, with whom, and why. Additionally, you may direct us through your actions on Frij.io to share your User Personal Information. </p>
        <h4 id="with-service-providers">With service providers</h4>
        <p>We share User Personal Information with a limited number of service providers who process it on our behalf to provide or improve our Service, and who have agreed to privacy restrictions similar to the ones in our Privacy Statement by signing data protection agreements or making similar commitments. Our service providers perform payment processing, customer support ticketing, network data transmission, security, and other similar services. While Frij.io processes all User Personal Information in the United States, our service providers may process data outside of the United States or the European Union. If you would like to know who our service providers are, please see our page on <a href="/github/site-policy/github-subprocessors-and-cookies">Subprocessors</a>.</p>
        <h4 id="for-legal-disclosure">For legal disclosure</h4>
        <p>Frij.io strives for transparency in complying with legal process and legal obligations. Unless prevented from doing so by law or court order, or in rare, exigent circumstances, we make a reasonable effort to notify users of any legally compelled or required disclosure of their information. Frij.io may disclose User Personal Information or other information we collect about you to law enforcement if required in response to a valid subpoena, court order, search warrant, a similar government order, or when we believe in good faith that disclosure is necessary to comply with our legal obligations, to protect our property or rights, or those of third parties or the public at large.</p>
        <h4 id="change-in-control-or-sale">Change in control or sale</h4>
        <p>We may share User Personal Information if we are involved in a merger, sale, or acquisition of corporate entities or business units. If any such change of ownership happens, we will ensure that it is under terms that preserve the confidentiality of User Personal Information, and we will notify you on our Website or by email before any transfer of your User Personal Information. The organization receiving any User Personal Information will have to honor any promises we made in our Privacy Statement or Terms of Service.</p>
        <h4 id="aggregate-non-personally-identifying-information">Aggregate, non-personally identifying information</h4>
        <p>We share certain aggregated, non-personally identifying information with others about how our users, collectively, use Frij.io, or how our users respond to our other offerings, such as our conferences or events. </p>
        <p>We <strong>do not</strong> sell your User Personal Information for monetary or other consideration.</p>
        <p>Please note: The California Consumer Privacy Act of 2018 (“CCPA”) requires businesses to state in their privacy policy whether or not they disclose personal information in exchange for monetary or other valuable consideration. While CCPA only covers California residents, we voluntarily extend its core rights for people to control their data to <em>all</em> of our users, not just those who live in California. You can learn more about the CCPA and how we comply with it <a href="/github/site-policy/githubs-notice-about-the-california-consumer-privacy-act">here</a>.</p>
        <h3 id="other-important-information">Other important information</h3>
        <h4 id="public-information-on-frij-io">Public information on Frij.io</h4>
        <p>Many of Frij.io services and features are public-facing. If your content is public-facing, third parties may access and use it in compliance with our Terms of Service, such as by viewing your profile. We do not sell that content; it is yours. However, we do allow third parties, such as research organizations or archives, to compile public-facing Frij.io information. Other third parties, such as data brokers, have been known to scrape Frij.io and compile data as well.</p>
        <p>If you would like to compile Frij.io data, you must comply with our <a href="https://frij.io/terms">Terms of Service</a>, and you may only use any public-facing User Personal Information you gather for the purpose for which our user authorized it, and you must have written consent from Frij.io. We expect you to reasonably secure any User Personal Information you have gathered from Frij.io, and to respond promptly to complaints, removal requests, and &quot;do not contact&quot; requests from Frij.io or Frij.io users.</p>
        <p>Similarly, projects on Frij.io may include publicly available User Personal Information collected as part of the collaborative process. If you have a complaint about any User Personal Information on Frij.io, please <a href="https://frij.io/contact">contact us</a>.</p>
        <h3 id="how-you-can-access-and-control-the-information-we-collect">How you can access and control the information we collect</h3>
        <p>If you&#39;re already a Frij.io user, you may access, or delete your basic user profile information by using the My Account modal or contacting <a href="https://frij.io/contact">Frij.io Support</a>.</p>
        <p>If Frij.io processes information about you, such as information Frij.io receives from third parties, and you do not have an account, then you may, subject to applicable law, access, update, alter, delete, or object to the processing of your personal information by contacting <a href="https://frij.io/contact">Frij.io Support</a>.</p>
        <h4 id="data-retention-and-deletion-of-data">Data retention and deletion of data</h4>
        <p>Generally, Frij.io retains User Personal Information for as long as your account is active or as needed to provide you services.</p>
        <p>If you would like to cancel your account or delete your User Personal Information, you may do so in your My Account modal. We retain and use your information as necessary to comply with our legal obligations, resolve disputes, and enforce our agreements, but barring legal requirements, we will delete your full profile (within reason) within 90 days of your request. You may contact <a href="https://frij.io/contact">Frij.io Support</a> to request the erasure of the data we process on the basis of consent within 30 days.</p>
        <p>After an account has been deleted, certain data will remain. However, we will delete or de-identify your User Personal Information, including your username and email address by associating them with a ghost user.</p>
        <h3 id="our-use-of-cookies-and-tracking">Our use of cookies and tracking</h3>
        <h4 id="cookies">Cookies</h4>
        <p>Frij.io uses cookies and similar technologies (e.g., HTML5 localStorage) to make interactions with our service easy and meaningful. Cookies are small text files that websites often store on computer hard drives or mobile devices of visitors. We use cookies and similar technologies (hereafter collectively &quot;cookies&quot;) to provide you our services, for example, to keep you logged in, remember your preferences, identify your device for security purposes, and provide information for future development of Frij.io. By using our Website, you agree that we can place these types of cookies on your computer or device. If you disable your browser or device’s ability to accept these cookies, you will not be able to log in or use Frij.io’s services.</p>
        <h4 id="tracking-and-analytics">Tracking and analytics</h4>
        <p>We use a number of third-party analytics and service providers to help us evaluate our Users&#39; use of Frij.io, compile statistical reports on activity, and improve our content and Website performance. We only use these third-party analytics providers on certain areas of our Website, and all of them have signed data protection agreements with us that limit the type of User Personal Information they can collect and the purpose for which they can process the information. In addition, we use our own internal analytics software to provide features and improve our content and performance.</p>
        <p>Some browsers have incorporated &quot;Do Not Track&quot; (DNT) features that can send a signal to the websites you visit indicating you do not wish to be tracked. Frij.io responds to browser DNT signals and follows the <a href="https://www.w3.org/TR/tracking-dnt/">W3C standard for responding to DNT signals</a>. If you have not enabled DNT on a browser that supports it, cookies on some parts of our Website will track your online browsing activity on other online services over time, though we do not permit third parties other than our analytics and service providers to track Frij.io Users&#39; activity over time on Frij.io.</p>
        <h3 id="how-frij-io-secures-your-information">How Frij.io secures your information</h3>
        <p>Frij.io takes all measures reasonably necessary to protect User Personal Information from unauthorized access, alteration, or destruction; maintain data accuracy; and help ensure the appropriate use of User Personal Information.</p>
        <p>Frij.io enforces a security information program. Our program:</p>
        <ul>
        <li>aligns with industry recognized frameworks;</li>
        <li>is appropriate to the nature, size, and complexity of Frij.io’s business operations;</li>
        </ul>
        <p>In the event of a data breach that affects your User Personal Information, we will act promptly to mitigate the impact of a breach and notify any affected Users without undue delay.</p>
        <p>No method of transmission, or method of electronic storage, is 100% secure. Therefore, we cannot guarantee its absolute security. </p>
        <h3 id="frij-io-s-global-privacy-practices">Frij.io&#39;s global privacy practices</h3>
        <p>We store and process the information that we collect in the United States in accordance with this Privacy Statement, though our service providers may store and process data outside the United States. However, we understand that we have Users from different countries and regions with different privacy expectations, and we try to meet those needs even when the United States does not have the same privacy framework as other countries.</p>
        <p>We provide the same high standard of privacy protection—as described in this Privacy Statement—to all our users around the world, regardless of their country of origin or location, and we are proud of the levels of notice, choice, accountability, security, data integrity, access, and recourse we provide. We work hard to comply with the applicable data privacy laws wherever we do business, working with our Data Protection Officer as part of a cross-functional team that oversees our privacy compliance efforts. Additionally, if our vendors or affiliates have access to User Personal Information, they must sign agreements that require them to comply with our privacy policies and with applicable data privacy laws.</p>
        <p>In particular:</p>
        <ul>
        <li>Frij.io provides clear methods of unambiguous, informed, specific, and freely given consent at the time of data collection, when we collect your User Personal Information using consent as a basis.</li>
        <li>We collect only the minimum amount of User Personal Information necessary for our purposes, unless you choose to provide more. We encourage you to only give us the amount of data you are comfortable sharing.</li>
        <li>We offer you simple methods of accessing, altering, or deleting the User Personal Information we have collected, where legally permitted.</li>
        <li>We provide our Users notice, choice, accountability, security, and access regarding their User Personal Information, and we limit the purpose for processing it. We also provide our Users a method of recourse and enforcement.</li>
        </ul>
        <h3 id="how-we-communicate-with-you">How we communicate with you</h3>
        <p>We use your email address to communicate with you, if you&#39;ve said that&#39;s okay, <strong>and only for the reasons you’ve said that’s okay</strong>. For example, if you contact our Support team with a request, we respond to you via email. </p>
        <p>Frij.io may occasionally send notification emails about, new features, requests for feedback, important policy changes, or to offer customer support. We also send marketing emails, based on your choices and in accordance with applicable laws and regulations. There&#39;s an “unsubscribe” link located at the bottom of each of the marketing emails we send you. Please note that you cannot opt out of receiving important communications from us, such as emails from our Support team or system emails, but you can configure your notifications settings in your profile to opt out of other communications.</p>
        <p>Our emails may contain a pixel tag, which is a small, clear image that can tell us whether or not you have opened an email and what your IP address is. We use this pixel tag to make our email more effective for you and to make sure we’re not sending you unwanted email.</p>
        <h3 id="resolving-complaints">Resolving complaints</h3>
        <p>If you have concerns about the way Frij.io is handling your User Personal Information, please let us know immediately. We want to help. You may contact us by filling out the <a href="https://frij.io/contact">contact form</a>. We will respond promptly — within 45 days at the latest.</p>
        <h4 id="dispute-resolution-process">Dispute resolution process</h4>
        <p>In the unlikely event that a dispute arises between you and Frij.io regarding our handling of your User Personal Information, we will do our best to resolve it. </p>
        <h3 id="changes-to-our-privacy-statement">Changes to our Privacy Statement</h3>
        <p>Although most changes are likely to be minor, Frij.io may change our Privacy Statement from time to time. We will provide notification to Users of material changes to this Privacy Statement through our Website at least 30 days prior to the change taking effect by posting a notice on our home page or sending email to the primary email address specified in your Frij.io account.</p>
        <h3 id="license">License</h3>
        <p>This Privacy Statement is licensed under this <a href="https://creativecommons.org/publicdomain/zero/1.0/">Creative Commons Zero license</a>.</p>
        <h3 id="contacting-frij-io">Contacting Frij.io</h3>
        <p>Questions regarding Frij.io&#39;s Privacy Statement or information practices should be directed to our <a href="https://support.github.com/contact">contact form</a>.</p>
      </div>
    );
  }
}

export default Privacy;