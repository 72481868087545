import React from "react";
import Container from "react-bootstrap/Container";
import {SWIPE_RELOAD_EXECUTE_DELTA} from "../helpers/constants"

class LeaderboardTileLoader extends React.Component {
  render() {
    return (
      <div 
        className= {this.props.loading ? "d-flex justify-content-center p-2 pb-3" : "d-flex justify-content-center align-items-end"} 
        style={{"min-height": this.props.height + "px"}}
      >
        {this.props.loading && <div className="spinner-border leaderboard_loader" role="status"></div>}
        {!this.props.loading && this.props.height > 20 && 
          <i 
            className="leaderboard__loader__reload_symbol fas fa-sync-alt fa-md pb-2 pt-0" 
            style={{"opacity": Math.min(this.props.height / SWIPE_RELOAD_EXECUTE_DELTA, 1)}}
          ></i>
        }
      </div>
    );
  }
}

export default LeaderboardTileLoader;
