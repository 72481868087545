import React from "react";
import Col from "react-bootstrap/Col"
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import {mapScoreToLevel} from '../helpers/functions'
import {LEVEL_NAMES} from "../helpers/constants"
import { storage, auth } from '../services/firebase';

class FridgeNavbarMyAccountUserInfo extends React.Component {
  constructor() {
    super();
    this.state = {
      hide_user_info: false // (window.innerWidth < HIDE_USER_INFO_WIDTH),
    };
    window.addEventListener('resize', this.handleResize);
  }

  handleUpload = async (e) => {
    const file = e.target.files[0];
    const path = 'avatars/' + auth().currentUser.uid;
    const avatarRef = storage.ref().child(path);
    await avatarRef.put(file);
    this.props.changeAvatar();
  }

  render() {
    return (
      <div class="d-flex justify-content-center pb-4">
        <OverlayTrigger overlay={<Tooltip id="tooltip-bottom">Upload profile picture!</Tooltip>}>
          <div className="my-account__user__avatar__wrapper">
            <input onChange={(e) => this.handleUpload(e)} type="file" name="file" id="avatar__file__input" class="account__user__avatar__upload" accept="image/*"/>
            <label for="avatar__file__input">
            <img  
              className="my-account__user__avatar"
              src={this.props.avatar_url}
            ></img>
            </label>
            <div class="my-account__user__avatar__upload-icon">
              <span class="fa-stack">
                <i class="fas fa-circle fa-stack-2x"></i>
                <i class="fas fa-camera fa-stack-1x"></i>
              </span>
            </div>
          </div>
        </OverlayTrigger>
        <Col xs={0}>
          <div className="my-account__user__handle">{this.props.fridge_handle}</div>
          <OverlayTrigger key={"bottom"} placement={"bottom"} overlay={<Tooltip id="tooltip-bottom">Your <b>Frij Creds</b> & <b>Frij Level</b></Tooltip>}>
            <div className="my-account__user__score">
              <i className={"fas fa-circle pr-1 user__level-" + mapScoreToLevel(this.props.score)}></i>
              {this.props.score.toLocaleString()} - <b>{LEVEL_NAMES[mapScoreToLevel(this.props.score)]}</b>
            </div>
          </OverlayTrigger>
        </Col>
      </div>
    );
  }
}

export default FridgeNavbarMyAccountUserInfo;