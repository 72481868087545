import React, { useCallback } from "react"; 
import { useSwipeable, Swipeable } from 'react-swipeable'
import Leaderboard from "./components/Leaderboard";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Footer from "./components/Footer";
import Magnets from "./components/Magnets";
import FridgeNavbar from "./components/FridgeNavbar";
import { auth, db, storage_ref } from './services/firebase';
import SignInModal from "./components/SignInModal";
import DefaultUsernameModal from "./components/DefaultUsernameModal";
import WelcomeModal from "./components/WelcomeModal";
import FullPageLoader from "./components/FullPageLoader";
import {MOBILE_WIDTH, COUNTRY_NAMES, SWIPE_START_DELTA, SWIPE_EXECUTE_DELTA, SWIPE_DELTA_TO_INCRAMENT, CONSTANT_SWIPE_RATE, SWIPE_COMPLETE_TOL, POST_SCORE_BONUS} from "./helpers/constants"
import {generateNewUsername, isTablet} from "./helpers/functions"
import firebase from 'firebase';

var moment = require('moment');

class App extends React.Component {
  constructor() {
    super();
    this.state = {
      client: {
        avatar: "",
        post_log: [],
        score: 0,
        sign_up_time: 0,
        username: "",
        vote_log: {},
        following: []
      },
      authenticated: false,
      email_verified: false,
      done_loading: false,
      sign_in_modal_active: false,
      username_modal_active: false,
      mobile_width: (window.innerWidth < MOBILE_WIDTH),
      base_words: [],
      word_bank: [],
      leaderboard_active: false,
      show_welcome_modal: false,
      swipe_offset: this.swipeOffsetDist(),
      main_row_ref: React.createRef(),
      leaderboard_filter: {following: false, date: moment(), country: "WW", sort: "votes", era: "week"},
      leaderboard_countries: ["WW"]
    };
    window.addEventListener('resize', this.handleResize);
  }

  handleFilterUpdate = (state_key, state_value) => {
    if (!this.state.authenticated && state_key === "following") {
      this.toggleSignInModal();
    }
    else {
      var temp_filter =  Object.assign({},this.state.leaderboard_filter);
      if (state_key != "reload") {
        temp_filter[state_key] = state_value;
      }
      this.setState({ leaderboard_filter: temp_filter });
    }
  }

  handleCreatePost = (today) => {
    this.setState({
      leaderboard_filter: {following: false, date: moment(), country: "WW", sort: "time", era: "day"},
    })
    this.updateClientScore(POST_SCORE_BONUS);
  }

  handleResize = () => {
    this.setState({
      mobile_width: (window.innerWidth < MOBILE_WIDTH),
      swipe_offset: this.swipeOffsetDist()
    })
  }

  updateClientScore = (delta) => {
    let temp_client = this.state.client
    temp_client.score = temp_client.score + delta;
    this.setState({ 
      client: temp_client
    });
  }

  updateClientVoteLog = (post_id, state_value) => {
    let temp_client = this.state.client
    temp_client.vote_log[post_id] = state_value;
    this.setState({ 
      client: temp_client
    });
  }

  loadDayData = async () => {
    try {
      const doc_ref = db.collection("days").doc(moment().format('YYYY-MM-DD'));
      let doc_data = await doc_ref.get();
      this.setState({ 
        base_words: doc_data.data().base_words, 
        word_bank: doc_data.data().word_bank, 
        leaderboard_countries: doc_data.data().countries
      });
    } catch (error) {
      console.log("Error getting documents: ", error);
    }
  }

  async componentDidMount() {
    // Check for first time visitor
    let visited = localStorage["alreadyVisited"];
    if(visited) {
      this.setState({ show_welcome_modal: false })
      //do not view Popup
    } else {
      //this is the first time
      localStorage["alreadyVisited"] = true;
      this.setState({ show_welcome_modal: true});
    }
    // Check for redirect
    auth().getRedirectResult().then( async (user_cred) => {
      // If redirect from google sign in and new user
      if (user_cred.user && user_cred.credential.providerId == "google.com" && user_cred.additionalUserInfo.isNewUser) {
        // Create temporary usersname
        var new_username = generateNewUsername();
        var querySnapshot = await db.collection("users").where("username", "==", new_username).get()
        while (querySnapshot.size > 0) {
          new_username = generateNewUsername();
          querySnapshot = await db.collection("users").where("username", "==", new_username).get()
        }
        // IMPORTANT: Any new variables added here must also be added to the "sign in with email" new user object in auth.js
        var temp_client_data = {
          avatar: "default",
          following: [],
          comment_log: [],
          post_log: {},
          score: 10,
          sign_up_time: firebase.firestore.FieldValue.serverTimestamp(),
          username: new_username,
          vote_log: {}
        }
        this.setState({
          authenticated: true,
          email_verified: true,
          client: temp_client_data
        });
        const new_user_data = db.collection('users').doc(user_cred.user.uid);
        await new_user_data.set(temp_client_data)
        this.toggleUsernameModal();
      }
    });
    // Auth state change
    auth().onAuthStateChanged( async (user) => {
      if (user && user.emailVerified) {
        var client_profile_doc = await db.collection("users").doc(auth().currentUser.uid).get();
        var temp_client_data = client_profile_doc.data();
        if (temp_client_data) {
          if (temp_client_data.avatar === "default" || temp_client_data.length < 100) {
            temp_client_data.avatar = require("../src/img/default_avatar.png");
          }
          this.setState({
            authenticated: true,
            email_verified: true,
            client: temp_client_data
          });
        }
      }
      else if (user && !user.emailVerified) { 
        this.setState({
          authenticated: true,
          email_verified: false,
        });
      }
      else {
        this.setState({
          authenticated: false,
          client: null,
        });
      }
    })
    await this.loadDayData()
    this.setState({ done_loading: true });
    this.setState({ swipe_offset: this.swipeOffsetDist() });
  }

  changeAvatar = async () => {
    var temp_client_data = this.state.client;
    // Raw version (for instant gratification)
    try {
      const path = 'avatars/' + auth().currentUser.uid;
      const avatar_url = await storage_ref.child(path).getDownloadURL();
      temp_client_data.avatar = avatar_url;
      // Update database
      const doc_ref = db.collection("users").doc(auth().currentUser.uid);
      doc_ref.update({avatar: avatar_url});
    } catch (error) {
      temp_client_data.avatar = require("./img/default_avatar.png");
    }
    this.setState({
      client: temp_client_data
    });
  }

  toggleSignInModal = () => {
    this.setState({
      sign_in_modal_active: !this.state.sign_in_modal_active
    });
  }

  toggleUsernameModal = () => {
    this.setState({
      username_modal_active: !this.state.username_modal_active
    });
  }

  closeWelcomeModal = () => {
    this.setState({
      show_welcome_modal: false
    });
  }

  showWelcomeModal = () => {
    this.setState({
      show_welcome_modal: true
    });
  }

  handleNavbarSelect = (selected_key) => {
    this.setState({
      leaderboard_active: (selected_key === "leaderboard")
    })
    if (this.state.mobile_width) {
      switch (selected_key) {
        case "leaderboard":
          var target = 0;
          break;
        case "magnets":
          var target = this.swipeOffsetDist();
          break
        default:
          var target = 0;
          break;
      }
    }
    this.animateSwipe(target);
  } 

  disableScroll = () => { 
    // Get the current page scroll position 
    var scrollTop = window.pageYOffset || document.documentElement.scrollTop; 
    var scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;

        // if any scroll is attempted, set this to the previous value 
        window.onscroll = function() { 
            window.scrollTo(scrollLeft, scrollTop); 
        }; 
  } 
    
  enableScroll = () => { 
      window.onscroll = function() {}; 
  } 

  swipeOffsetDist = () => {
    try {
      return this.state.main_row_ref.current.offsetWidth;
    } catch (error) {
      return window.innerWidth;
    }
  } 
  
  swipingHandler = (eventData) => {
    if (eventData.first) {
      this.setState({
        swipe_origin: this.state.swipe_offset
      });
    }
    if (eventData.dir === "Left" || eventData.dir === "Right") {
      this.disableScroll();
      if ((this.state.leaderboard_active && eventData.deltaX > 0) || (!this.state.leaderboard_active && eventData.deltaX < 0)) {
        this.setState({
          swipe_offset: this.state.swipe_origin + eventData.deltaX
        });
      }
    }
    else {
      this.enableScroll();
    }
  }

  animateSwipe(target) {
    var delta = target - this.state.swipe_offset;
    var dir = Math.sign(delta);
    var incrament = Math.ceil((delta * SWIPE_DELTA_TO_INCRAMENT) + (1 * dir));
    if (Math.abs(incrament) > CONSTANT_SWIPE_RATE) {
      incrament = CONSTANT_SWIPE_RATE * dir ; 
    }
    this.setState({
      swipe_offset: this.state.swipe_offset + incrament
    });
    if (Math.abs(target - this.state.swipe_offset) > SWIPE_COMPLETE_TOL) {
      window.requestAnimationFrame(this.animateSwipe.bind(this,target));
    }
    else {
      window.cancelAnimationFrame(this.animateSwipe.bind(this,target)) 
      this.setState({
        swipe_offset: target
      });
    }
  }

  swipedHandler = (eventData) => {
    if (eventData.dir === "Left") {
      if (eventData.absX > SWIPE_EXECUTE_DELTA) {
        this.handleNavbarSelect("magnets")
      }
      else {
        this.animateSwipe(0)
      }
    }
    else if (eventData.dir === "Right") {
      if (eventData.absX > SWIPE_EXECUTE_DELTA) {
        this.handleNavbarSelect("leaderboard")
      }
      else {
        this.animateSwipe(this.swipeOffsetDist())
      }
    }
    else {
      if (this.state.mobile_width) {
        var target = this.state.leaderboard_active ? 0 : this.swipeOffsetDist();
        this.animateSwipe(target);
      }
    }
    this.enableScroll();
  }

  resetSwipeOffset = () => {
    var reset_position;
    if (this.state.leaderboard_active) {
      reset_position = 0;
    }
    else {
      reset_position = this.swipeOffsetDist();
    }
    this.setState({
      swipe_offset: reset_position
    })
  }

  handleFollowUser = async (uid) => {
    if (!this.state.authenticated) {
      this.toggleSignInModal();
    }
    else {
      try {
        const doc_ref = db.collection("users").doc(auth().currentUser.uid);
        let temp_client = this.state.client;
        if (this.state.client.following.includes(uid)) {
          temp_client.following = Array.from(new Set(this.state.client.following).delete(uid));
        }
        else {
          temp_client.following = Array.from(new Set(this.state.client.following).add(uid));
        }
        const following_update = await doc_ref.update({
          following: temp_client.following
        });
        this.setState({
          client: temp_client
        })
      } catch (error) {
        console.log("Error getting documents: ", error);
      }
    }
  }
  render() {
    return (
      <div>
        { this.state.done_loading ?
          <span>
            <FridgeNavbar 
              user = {this.state.client} 
              signIn = {this.toggleSignInModal} 
              logged_in = {this.state.authenticated && this.state.email_verified} 
              mobile_width = {this.state.mobile_width} 
              leaderboard_active = {this.state.leaderboard_active} 
              handleNavbarSelect={this.handleNavbarSelect}
              changeAvatar={this.changeAvatar}
              showWelcomeModal={this.showWelcomeModal}
            />
            <Container fluid={"md"} className={!this.state.mobile_width ? "container--thin_margin" : ""}>
              <Swipeable 
                onSwiping={(eventData) => !isTablet() ? this.swipingHandler(eventData): null} 
                delta={SWIPE_START_DELTA} 
                onSwiped={(eventData) => !isTablet() ? this.swipedHandler(eventData) : null}
              >
                <Row ref={this.state.main_row_ref} className={!this.state.mobile_width ? "main-row" : "main-row row--mobile_swipable_width"}>
                  <Col style={{right: (this.state.mobile_width ? String(this.state.swipe_offset) : 0) + "px"}} className={this.state.mobile_width && "mobile_swipable_width col-xs-12"}>
                    <Leaderboard 
                      filter = {this.state.leaderboard_filter} 
                      updateFilter = {this.handleFilterUpdate} 
                      toggleSignInModal = {this.toggleSignInModal} 
                      countries = {this.state.leaderboard_countries} 
                      user = {this.state.client} 
                      updateVoteLog = {this.updateClientVoteLog} 
                      handleFollowUser = {this.handleFollowUser}
                      logged_in = {this.state.authenticated && this.state.email_verified}
                      updateClientScore = {this.updateClientScore}
                    />
                  </Col> 
                  <Col style={{right: (this.state.mobile_width ? String(this.state.swipe_offset) : 0) + "px"}} className={this.state.mobile_width && "mobile_swipable_width col-xs-12"}>
                    <Magnets 
                      base_words={this.state.base_words} 
                      word_bank={this.state.word_bank} 
                      handleCreatePost = {this.handleCreatePost} 
                      user = {this.state.client} 
                      logged_in = {this.state.authenticated && this.state.email_verified} 
                      toggleSignInModal = {this.toggleSignInModal}
                    />
                  </Col>
                </Row>
              </Swipeable>
              <Footer />          
              <DefaultUsernameModal show = {this.state.username_modal_active} toggleModal = {this.toggleUsernameModal} user = {this.state.client}/>
              <SignInModal authenticated = {this.state.authenticated} email_verified = {this.state.email_verified} show = {this.state.sign_in_modal_active} toggleModal = {this.toggleSignInModal}/>
              <WelcomeModal show = {this.state.show_welcome_modal} closeModal = {this.closeWelcomeModal}/>
            </Container>
          </span> : 
          <FullPageLoader/>
        }
      </div>
    );
  }
}

export default App; 