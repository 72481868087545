import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Sentence from "./Sentence"
import Word from "./Word"
import OverlayTrigger from "react-bootstrap/OverlayTrigger"
import Tooltip from "react-bootstrap/Tooltip"
import Draggable from 'react-draggable';
import { functions } from '../services/firebase';
import {padInt, indexOfMin} from '../helpers/functions'
import {MAX_WORD_BANK_MAGNITUDE, MONTHS} from '../helpers/constants'
import {mobileCheck} from "../helpers/functions"

class Magnets extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sentence: [],
      word_bank: [],
      base_words: [],
      base_words_remaining: 8,
      top_z_index: 0,
      today: new Date,
      post_button_clicked: false,
      post_loader_active: false,
      date_string: ""
    };
    window.addEventListener('resize', this.handleResize);
    this.composer_div_ref = React.createRef();
    this.base_words_div_ref = React.createRef();
  }

  componentDidMount = () => {
    const temp_base_words = this.props.base_words.map((item) => ({word: item, active: true, z_index: 0, position: {x: 0, y: 0}, ref: React.createRef()}))
    const temp_word_bank = this.props.word_bank.map((item) => ({word: item.word, active: true, adword_link: item.adword_link, z_index: 0, position: {x: 0, y: 0}, ref: React.createRef()}))
    this.setState({
      date_string:  this.state.today.getFullYear() + "-" + padInt(this.state.today.getMonth()+1,2) + "-" + padInt(this.state.today.getDate(),2),
      base_words: temp_base_words,
      word_bank: temp_word_bank,
    });
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.word_bank != this.props.word_bank || prevProps.base_words != this.props.base_words) {
      const temp_base_words = this.props.base_words.map((item) => ({word: item, active: true, z_index: 0, position: {x: 0, y: 0}, ref: React.createRef()}))
      const temp_word_bank = this.props.word_bank.map((item) => ({word: item.word, active: true, adword_link: item.adword_link, z_index: 0, position: {x: 0, y: 0}, ref: React.createRef()}))
      this.setState({
        base_words: temp_base_words,
        word_bank: temp_word_bank,
      });
    }
  }

  allow_post = () => {
    if (this.props.logged_in) {
      return !(this.state.date_string in this.props.user.post_log) && !this.state.post_button_clicked;
    }
    return true;
  }

  findIndex = (is_base_word, target_word) => {
    let temp_word_dict = is_base_word ? this.state.base_words : this.state.word_bank;
    for (var i in temp_word_dict) {
      if (temp_word_dict[i].word === target_word) {
        return i;
      }
    }
  }

  postSentence = async () => {
    // Create sentence data to send
    this.setState({post_loader_active: true}); 
    const sentence_data = this.state.sentence.map((item) => ({is_base_word: item.is_base_word, index: this.findIndex(item.is_base_word, item.word)}));
    const date_string = this.state.today.getFullYear() + "-" + padInt(this.state.today.getMonth()+1,2) + "-" + padInt(this.state.today.getDate(),2);
    var author_country = "";
    try {
      var query = await fetch('https://ipinfo.io?token=cd632eb1277cc6')
        .then((response) => response.json())
        .then((data) => {
          author_country = data.country;
        });
    }
    catch {
      author_country = "??"
    }

    if (this.props.logged_in) {
      // Update database
      if (this.allow_post()) {
        try {
          var postSentenceFirebase = functions.httpsCallable('createPost');
          var post_response = await postSentenceFirebase({
            sentence: sentence_data, 
            country: author_country,
            date: date_string
          });
          this.setState({post_button_clicked: true}); 
          this.props.handleCreatePost();
        } catch (error) {
          console.log("Error creating post: ", error);
        }
      } else {
        console.log("already created post today");
      }
    } else {
      this.props.toggleSignInModal();
    }
    this.setState({post_loader_active: false}); 
  }

  handleResize = () => {
    this.resetAllWordPositions();
  }

  removeWordFromSentence = (i) => {
    const word_to_remove = this.state.sentence[i];
    var temp_sentence = this.state.sentence;
    if (word_to_remove.is_base_word) {
      this.setState({ 
        base_words_remaining: this.state.base_words_remaining +1
      });
    } else {
      var temp_word_bank = this.state.word_bank;
      temp_word_bank[word_to_remove.index].active = true; 
      this.setState({ 
        word_bank: temp_word_bank
      });
    }
    temp_sentence.splice(i,1);
    this.setState({ 
      sentence: temp_sentence
    });
  }

  computeSentenceBoundaries = () => {
    let boundaries = [];
    if(this.state.sentence.length > 0) {
      for (var item of this.state.sentence) {
        const item_bounding_box = item.ref.current.getBoundingClientRect();
        boundaries.push({
          x: (item_bounding_box.left),
          y: ((item_bounding_box.top + item_bounding_box.bottom) / 2)
        })
      }
      const item_bounding_box = this.state.sentence[this.state.sentence.length-1].ref.current.getBoundingClientRect();
      boundaries.push({
        x: (item_bounding_box.right),
        y: ((item_bounding_box.top + item_bounding_box.bottom) / 2)
      })
    }
    return boundaries;
  }

  resetAllWordPositions = () => {
    let temp_word_bank = this.state.word_bank;
    let temp_base_words = this.state.base_words;
    for (var word of temp_word_bank) {
      word.position = {x: 0, y: 0};
    }
    for (var word of temp_base_words) {
      word.position = {x: 0, y: 0};
    }
    this.setState({
      word_bank: temp_word_bank,
      base_words: temp_base_words
    });
  }

  recenterDraggablesInScentence = () => {
    let temp_word_bank = this.state.word_bank;
    let temp_base_words = this.state.base_words;
    if(this.state.sentence.length > 0) {
      for (var item of this.state.sentence) {
        // Get scentence position
        const scentence_item_bounding_box = item.ref.current.getBoundingClientRect();
        const scentence_item_x = ((scentence_item_bounding_box.right + scentence_item_bounding_box.left) / 2);
        const scentence_item_y = ((scentence_item_bounding_box.top + scentence_item_bounding_box.bottom) / 2);
        // Get draggable aboslute position
        const draggable_bounding_box = item.draggable_ref.current.getBoundingClientRect();
        const draggable_x = ((draggable_bounding_box.right + draggable_bounding_box.left) / 2);
        const draggable_y = ((draggable_bounding_box.top + draggable_bounding_box.bottom) / 2);
        // Set new dragggable position
        if (item.is_base_word) {
          temp_base_words[item.index].position = {
            x: temp_base_words[item.index].position.x + (scentence_item_x - draggable_x), 
            y: temp_base_words[item.index].position.y + (scentence_item_y - draggable_y)
          };
        }
        else {
          temp_word_bank[item.index].position = {
            x: temp_word_bank[item.index].position.x + (scentence_item_x - draggable_x), 
            y: temp_word_bank[item.index].position.y + (scentence_item_y - draggable_y)
          };        
        }
      }
      this.setState({
        word_bank: temp_word_bank,
        base_words: temp_base_words
      });
    }
  }

  onStartMobile = (e, is_base_word, word_index) => {
    let temp_word_dict = is_base_word ? this.state.base_words : this.state.word_bank;
    let temp_sentence = this.state.sentence;

    if((!is_base_word && temp_word_dict[word_index].active) || (is_base_word && this.state.base_words_remaining > 0)) {
      const new_sentence_word = {is_base_word: is_base_word, index: word_index, word: temp_word_dict[word_index].word, ref: React.createRef()}
      temp_sentence.splice(temp_sentence.length, 0, new_sentence_word);
      if (is_base_word) {
        this.setState({ 
          base_words_remaining: this.state.base_words_remaining - 1
        });
      } else {
        temp_word_dict[word_index].active = false;
      }
    }

    if (is_base_word) {
      this.setState({
        base_words: temp_word_dict
      })
    } else {
      this.setState({
        word_bank: temp_word_dict
      })
    } 
  }

  onStopMobile = () => false; 

  onStart = (e, data) => {
    let is_base_word = (String(data.node.id.substring(0, 9)) === "base_word");
    let word_index = Number(data.node.id.substr(data.node.id.length - MAX_WORD_BANK_MAGNITUDE));
    let temp_word_dict = is_base_word ? this.state.base_words : this.state.word_bank;

    temp_word_dict[word_index].z_index = this.state.top_z_index + 1; 
    temp_word_dict[word_index].start_position = {x: data.lastX, y: data.lastY};
    temp_word_dict[word_index].word_bounding_box_at_start = data.node.getBoundingClientRect();

    if(!temp_word_dict[word_index].active) {
      var target = null;
      for (var index in this.state.sentence) {
        if (temp_word_dict[word_index].ref === this.state.sentence[index].draggable_ref) {
          target = index;
        }
      }
      this.removeWordFromSentence(target);
      temp_word_dict[word_index].active = true;
    }
    
    if (is_base_word) {
      this.setState({
        top_z_index: this.state.top_z_index + 1,  
        base_words: temp_word_dict
      })
    } else {
      this.setState({
        top_z_index: this.state.top_z_index + 1,  
        word_bank: temp_word_dict
      })
    }
  };

  onStop = (e, data) => {

    let word_index = Number(data.node.id.substr(data.node.id.length - MAX_WORD_BANK_MAGNITUDE));
    const word_bounding_box = data.node.getBoundingClientRect();
    const composer_bounding_box = this.composer_div_ref.current.getBoundingClientRect();
    const composer_overlap = !(word_bounding_box.right < composer_bounding_box.left || 
      word_bounding_box.left > composer_bounding_box.right || 
      word_bounding_box.bottom < composer_bounding_box.top + 7 || 
      word_bounding_box.top > composer_bounding_box.bottom);
    const word_centroid = {
      x: ((word_bounding_box.right + word_bounding_box.left) / 2),
      y: ((word_bounding_box.top + word_bounding_box.bottom) / 2)
    }
    var offset = {x: 0, y: 0};

    const boundaries = this.computeSentenceBoundaries();
    let temp_sentence = this.state.sentence;
    var insertion_index = null;
    if (boundaries.length > 0) { 
      let euclid_dist = [];
      for (var boundary of boundaries) {
        euclid_dist.push(Math.hypot(boundary.x - word_centroid.x, boundary.y - word_centroid.y));
      }
      insertion_index = indexOfMin(euclid_dist);
    } else {
      insertion_index = 0; 
    }

    const is_base_word = (data.node.id.substring(0, 9) === "base_word");
    if (is_base_word) {
      let temp_word_dict = this.state.base_words;

      const base_words_bounding_box = this.base_words_div_ref.current.getBoundingClientRect() ;
      const base_words_overlap = !(word_bounding_box.right < base_words_bounding_box.left || 
        word_bounding_box.left > base_words_bounding_box.right || 
        word_bounding_box.bottom < base_words_bounding_box.top + 7 || 
        word_bounding_box.top > base_words_bounding_box.bottom);

      if (base_words_overlap) {
        if (temp_word_dict[word_index].start_position.x != 0 && temp_word_dict[word_index].start_position.y != 0) {
          temp_word_dict.splice(word_index,1);
          this.setState({ 
            base_words: temp_word_dict, 
          });
        }
        else {
          temp_word_dict[word_index].position = {x: 0, y: 0};  
        }
      }
      else {
        if (temp_word_dict[word_index].start_position.x === 0 && temp_word_dict[word_index].start_position.y === 0) {

          offset.x = temp_word_dict[word_index].word_bounding_box_at_start.left;
          offset.y = temp_word_dict[word_index].word_bounding_box_at_start.top;

          var duplicate_base_word = {word: temp_word_dict[word_index].word, active: true, z_index: 0, position: {x: 0, y: 0}, ref: React.createRef()};
          temp_word_dict[word_index].position = {x: 0, y: 0};  
          word_index = temp_word_dict.push(duplicate_base_word) - 1;

          this.setState({ 
            base_words: temp_word_dict,
          });

          var new_word_bounding_box = temp_word_dict[word_index].ref.current.getBoundingClientRect()
          offset.x = offset.x - new_word_bounding_box.left;
          offset.y = offset.y - new_word_bounding_box.top;
        }
      }

      if (composer_overlap && !base_words_overlap) {
        if(this.state.base_words_remaining > 0) {
          this.setState({ 
            base_words_remaining: this.state.base_words_remaining - 1
          });
          temp_word_dict[word_index].active = false;
          const new_sentence_word = {is_base_word: (data.node.id.substring(0, 9) === "base_word"), index: word_index, word: temp_word_dict[word_index].word, ref: React.createRef(), draggable_ref: temp_word_dict[word_index].ref}
          temp_sentence.splice(insertion_index, 0, new_sentence_word);
          this.setState({ 
            sentence: temp_sentence
          });
        }
      }

      if (word_index < temp_word_dict.length && !base_words_overlap) {
        temp_word_dict[word_index].position = {x: data.x + offset.x, y: data.y + offset.y};  
      }

      this.setState({
        base_words: temp_word_dict
      })

    }
    else {
      let temp_word_dict = this.state.word_bank;

      if (composer_overlap) {
        if(temp_word_dict[word_index].active) {
          temp_word_dict[word_index].active = false;
          const new_sentence_word = {is_base_word: (data.node.id.substring(0, 9) === "base_word"), index: word_index, word: temp_word_dict[word_index].word, ref: React.createRef(), draggable_ref: temp_word_dict[word_index].ref}
          temp_sentence.splice(insertion_index, 0, new_sentence_word);
          this.setState({ 
            sentence: temp_sentence
          });
        }
      }
      
      temp_word_dict[word_index].position = {x: data.x, y: data.y};  

      this.setState({
        word_bank: temp_word_dict
      })

    }

    this.recenterDraggablesInScentence();   
  };

  render() {
    const dragHandlers = mobileCheck() ? {onStart: this.onStartMobile, onStop: this.onStopMobile} : {onStart: this.onStart, onStop: this.onStop};
    var date = MONTHS[this.state.today.getMonth()] + " " + this.state.today.getDate() + ", " + this.state.today.getFullYear();
    const sentence_word_list = this.state.sentence.map((item) => item.word);
    const sentence_ref_list = this.state.sentence.map((item) => item.ref);
    const base_word_list = this.state.base_words.map((item, index) =>
      mobileCheck() ? 
      <div 
        className="magnets__draggable_word_wrapper" 
        id={"base_word_index_"+padInt(index,MAX_WORD_BANK_MAGNITUDE)} 
        style={{zIndex: item.z_index}}
        onClick={(e) => this.onStartMobile(e,true,index)}
      >
        <Word word={item.word} active={item.active} adword_link=""/>
      </div> :
      <Draggable 
        bounds={".magnets__background-light-gray"} 
        position={item.position}
        {...dragHandlers} 
      >
        <div 
          className="magnets__draggable_word_wrapper" 
          id={"base_word_index_"+padInt(index,MAX_WORD_BANK_MAGNITUDE)} 
          style={{zIndex: item.z_index}}
          ref={item.ref}
        >
          <Word word={item.word} active={item.active} adword_link=""/>
        </div>
      </Draggable>
    );
    const word_bank_items = this.state.word_bank.map((item, index) =>
      mobileCheck() ? 
      <div 
        className="magnets__draggable_word_wrapper" 
        id={"word_bank_index_"+padInt(index,MAX_WORD_BANK_MAGNITUDE)} 
        style={{zIndex: item.z_index}}
        onClick={(e) => this.onStartMobile(e,false,index)}
      >
        <Word word={item.word} active={item.active} adword_link={item.adword_link}/>
      </div> :
      <Draggable 
        bounds={".magnets__draggable_area"} 
        position={item.position}
        {...dragHandlers} 
      >
        <div 
          className="magnets__draggable_word_wrapper" 
          id={"word_bank_index_"+padInt(index,MAX_WORD_BANK_MAGNITUDE)} 
          style={{zIndex: item.z_index}}
          ref={item.ref}
        >
          <Word word={item.word} active={item.active} adword_link={item.adword_link}/>
        </div>
      </Draggable>
    );
    return (
      <div className="magnets">
        <div className="magnets__background-light-gray">
          <div className="magnets__background-white">
            <div className="magnets__heading pt-3">
              <h1 className="mb-1">Today's Fridge Magnets</h1>
              <p>{date}</p>
            </div>
            <div className="magnets__draggable_area">
              <div className="magnets__word-bank">
                {word_bank_items}
              </div>
              <Row ref={this.composer_div_ref} className="magnets__compose-sentence p-2">  
                <Col className="magnets__compose-sentence__input p-0 pl-1">
                  <Sentence 
                    sentence={sentence_word_list} 
                    refs={sentence_ref_list} 
                    removeWordFromSentence={mobileCheck() ? this.removeWordFromSentence: () => {}} 
                  />
                </Col>
                <Col className="magnets__compose-sentence__button p-0">
                {this.state.post_loader_active && <div class="spinner-border magnets__post-loader" role="status"></div>}
                {this.allow_post() && !this.state.post_loader_active &&
                  <Button onClick={this.postSentence} className="float-right">Post</Button>
                }
                {!this.allow_post() && !this.state.post_loader_active &&
                  <OverlayTrigger
                    placement="left"
                    delay={{ show: 250, hide: 500 }}
                    overlay={<Tooltip><strong>One post per day...</strong> Check back tomorrow for awesome new words!</Tooltip>}
                  >
                    <Button className="float-right magnets__compose-sentence__button--inactive">Post</Button> 
                  </OverlayTrigger>
                }
                </Col>
              </Row>
            </div>
          </div>
          <div className="magnets__base-words p-3" ref={this.base_words_div_ref} >
            <Row className="magnets__base-words__heading mb-1">
              <Col>
                <b>Base Words:</b>
              </Col>
              <Col className="text-right">
                {this.state.base_words_remaining} left
              </Col>
            </Row>
            <Row className="magnets__base-words__list m-0 pb-2">
              {base_word_list}
            </Row>
          </div>
        </div>
      </div>
    );
  }
}

export default Magnets;