import React from "react";
import Button from "react-bootstrap/Button"
import UserStatsModalInfo from "./UserStatsModalInfo"
import Modal from "react-bootstrap/Modal";
import UserSpecificFeed from "./UserSpecificFeed";

var moment = require('moment');

class UserStatsModal extends React.Component {

  handleFollowUser = () => {
    this.props.handleFollowUser(this.props.uid)
  }

  render() {
    return (
      <Modal className="my-account__modal" show={this.props.show} onHide={this.props.hideUserStatsModal}>
        <Modal.Header className="my-account__modal__header" closeButton></Modal.Header>
        <Modal.Body className="my-account__modal__body">
          <UserStatsModalInfo 
            fridge_handle = {this.props.user.username}
            score = {this.props.user.score}
            avatar_url = {this.props.user.avatar}
          />
          { this.props.following ? 
            <Button block active={false} className="my-account__btn my-account__btn--secondary" onClick={this.handleFollowUser}>{this.props.following.includes(this.props.uid) ? "Unfollow" : "Follow"}</Button> : 
            <Button block active={false} className="my-account__btn my-account__btn--secondary" onClick={this.handleFollowUser}>Follow</Button> 
          }
          <UserSpecificFeed 
            filter = {{following: false, date: moment(), country: "WW", sort: "votes", era: "all"}}
            toggleSignInModal = {this.props.toggleSignInModal} 
            user = {this.props.client} 
            updateVoteLog = {this.props.updateClientVoteLog} 
            logged_in = {this.props.logged_in}
            updateClientScore = {this.props.updateClientScore}
            user_of_interest = {this.props.uid}
          />
        </Modal.Body>
        <Modal.Footer className="my-account__modal__footer">
          <Button block className="my-account__btn" onClick={this.props.hideUserStatsModal}>Done</Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default UserStatsModal;