import { auth, db } from "../services/firebase";
import firebase from 'firebase';
import { functions } from '../services/firebase';

/* REFERRAL */
// async function checkReferralCode(referral_code) {
//   var query = await db.collection("codes").doc(referral_code).get();
//   return query.exists;
// }
/* REFERRAL */

export function signUp(email, password, new_username, referral_code) {
  return new Promise( async (resolve,reject) => { 
    /* REFERRAL */
    // var valid = await checkReferralCode(referral_code);
    // if(!valid) {
    //   console.log("invalid referral code")
    //   reject("invalid referral code");
    // } else {
    //   db.collection("codes").doc(referral_code).delete();
    /* REFERRAL */
    db.collection("users").where("username", "==", new_username)
      .get()
      .then( async function(querySnapshot) {
        if (querySnapshot.size > 0) {
          reject("username taken");
        } 
        else {
          // IMPORTANT: Any new variables added here must also be added to the "sign in with google" new user object in Main.js
          var new_user_data = {
            avatar: "default",
            following: [],
            comment_log: [],
            post_log: {},
            score: 10,
            sign_up_time: firebase.firestore.FieldValue.serverTimestamp(),
            username: new_username,
            vote_log: {}
          }
          try {
            await auth().createUserWithEmailAndPassword(email, password)
            const new_user_doc = db.collection('users').doc(auth().currentUser.uid);
            await new_user_doc.set(new_user_data)
            await auth().currentUser.sendEmailVerification();
            resolve();
          }
          catch (error) {
            reject(error.message);
            console.log(error.message)
          };  
        }
      })
      .catch(function(error) {
          console.log("Error getting documents: ", error);
      });    
    //} // REFERRAL
  });
}
  
export function sendEmailVerification() {
  auth().currentUser.sendEmailVerification();
}

export function signIn(email, password) {
  auth().signOut();
  return auth().signInWithEmailAndPassword(email, password);
}

export async function signInWithGoogle() {
  // Start sign in process
  const provider = new auth.GoogleAuthProvider();
  auth().signInWithRedirect(provider);
}

export async function changeUsername(new_username) {
  return new Promise( async (resolve, reject) => {
    var querySnapshot = await db.collection("users").where("username", "==", new_username).get()
    if (querySnapshot.size > 0) {
      reject("username taken");
    } 
    else {
      try {
        var changeUsername = functions.httpsCallable('changeUsername');
        var username_response = await changeUsername({
          new_username: new_username
        });
      } catch (error) {
        console.log("Error changing username: ", error);
      }
    }
    resolve();
  });
}

export function forgotPassword(email) {
  auth().sendPasswordResetEmail(email);
  alert("📬 Email sent to " + email + " 📬\nPlease check your email to complete the password reset process.");
}

export function resetPassword() {
  const email = auth().currentUser.email;
  auth().sendPasswordResetEmail(email);
  alert("📬 Email sent to " + email + " 📬\nPlease check your email to complete the password reset process.");
}

export function signOut() {
  return auth().signOut().addOnCompleteListener(window.location.reload(false));
}

export async function deleteAccount() {
  var confirmation = window.confirm("Are you sure you want to delete your account?");
  if (confirmation) {
    var deleteUserContent = functions.httpsCallable('deleteUserContent');
    var records_deleted = await deleteUserContent().catch((error) => {
      console.log(error);
    });
    console.log("Number or user records deleted (comments + posts): ", records_deleted);
    if (records_deleted) {
      await auth().currentUser.delete();
      window.location.reload(false);
    }
    else {
      alert("There was an error deleting your account. Please contact Fridge Magnets support.")
    }
  }
}