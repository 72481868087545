import React from "react";
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Form from "react-bootstrap/Form"
import Button from "react-bootstrap/Button"
import {db} from '../services/firebase';
import FullPageLoader from "./FullPageLoader";

class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      message: "",
      sent: false,
      timestamp: new Date
    };
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value, 
    });
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    this.setState({timestamp: new Date});
    const new_contact_ref = db.collection("contact").doc();
    await new_contact_ref.set(this.state);
    this.setState({
      sent: true,
    });
  }

  returnToMain = async (event) => {
    window.location.href = '/';
  }
  
  render() {
    return (
      <div>
        { this.state.loading ?
          <FullPageLoader /> :
          <Container className="container__contact px-5">
            <Row>
              <Col md={{ span: 8, offset: 2 }}>
                <div>
                  <h1 className="mt-5">Contact Us:</h1>
                  <p>Please feel free to reach out with any inquiry.</p>
                  <hr></hr>
                  {this.state.sent ? 
                    <p>Thanks for reaching out! We'll get back to you as soon as we can.</p> :
                    <Form onSubmit={this.handleSubmit} className="">
                        <p className="mb-1 pl-2 container__contact__form"><b>Name:</b></p>
                        <Form.Control
                          className="mt-1 mb-4"
                          type="text"
                          name="name"
                          placeholder="name"
                          onChange={this.handleChange}
                          value={this.state.name}
                          required
                        />
                        <p className="mb-1 pl-2 container__contact__form"><b>Email:</b></p>
                        <Form.Control
                          className="mt-1 mb-4"
                          type="email"
                          name="email"
                          placeholder="email"
                          onChange={this.handleChange}
                          value={this.state.email}
                          required
                        />
                        <p className="mb-1 pl-2 container__contact__form"><b>Message:</b></p>
                        <Form.Control 
                          className="mt-1 mb-4"
                          as="textarea" 
                          rows={3}
                          name="message"
                          placeholder="message"
                          onChange={this.handleChange}
                          value={this.state.message}
                          required 
                        />
                      <Button className="mt-3 mb-5 px-4" type="submit">Submit</Button>
                    </Form>
                  }
                  <Button className="mt-2 mb-5 px-4" onClick={this.returnToMain}>Back to Main Page</Button>
                </div>
              </Col>
            </Row>
          </Container>
        }
      </div>
    );
  }
}

export default Contact;