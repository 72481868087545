import React from "react";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import ToggleButton from "react-bootstrap/ToggleButton";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";
import LeaderboardDatePicker from "./LeaderboardDatePicker";
import {SORT_PRETTY_NAME, ERA_PRETTY_NAME, COUNTRY_NAMES} from "../helpers/constants"
import { mobileCheck } from "../helpers/functions";

const false_function = () => false;

class LeaderboardFilterBar extends React.Component {

  handleDateSelect = (selected_date) => {
    this.props.updateFilter("date", selected_date);
  }

  handleCountrySelect = (selection) => {
    this.props.updateFilter("country", selection);
  }

  handleSortSelect = (selection) => {
    this.props.updateFilter("sort", selection);
  }

  handleFollowingSelect = () => {
    this.props.updateFilter("following", !this.props.filter.following);
  }

  handleEraSelect = (selection) => {
    this.props.updateFilter("era", selection);
  }
 
  render() { 
    var ordered_countries = ["WW"]
    if (this.props.countries) {
      ordered_countries = ordered_countries.concat(this.props.countries.filter(key => key != "WW").sort());
    }
    const countryList = ordered_countries.map((key) =>
      <Dropdown.Item eventKey={key}>{COUNTRY_NAMES[key]}</Dropdown.Item>
    );
    return ( 
    <Container fluid className= "leaderboard__filter-bar p-0 mb-3">
      <Row className="ml-1 mt-2">
        <div className="mr-1">
        {!mobileCheck() ? 
          <OverlayTrigger
            placement="bottom"
            delay={{ show: 500, hide: 50 }}
            overlay={<Tooltip><strong>Users I follow: </strong>Click any username to follow their posts</Tooltip>}
          >
            <Button className= "leaderboard-filter__btn--secondary" onClick={this.handleFollowingSelect}>
              <i className={this.props.filter.following ? "fas fa-star" : "far fa-star"} onClick={this.handleFollowersSelect}></i>
            </Button>
          </OverlayTrigger> 
        :
          <Button className= "leaderboard-filter__btn--secondary" onClick={this.handleFollowingSelect}>
            <i className={this.props.filter.following ? "fas fa-star" : "far fa-star"} onClick={this.handleFollowersSelect}></i>
          </Button>
        }
          
        </div>
        {/* <div>
          <LeaderboardDatePicker 
            displayFormat="MM/DD" 
            update={this.handleDateSelect} 
            daySize={33} 
            isOutsideRange={false_function} 
            initialDate={this.props.filter.date} 
            numberOfMonths={1} 
            hideKeyboardShortcutsPanel 
            noBorder 
            small 
            readOnly
            customInputIcon={<i className="fas fa-calendar-alt"></i>} 
          />
        </div>
        <div>
          <Dropdown onSelect={this.handleCountrySelect} className="leaderboard__filter-bar__dropdown ml-1">
            <Dropdown.Toggle>
              <span><i className="fas fa-globe-americas"></i> {COUNTRY_NAMES[this.props.filter.country]}</span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {countryList}
            </Dropdown.Menu>
          </Dropdown> 
        </div> */}
        <div>
          <Dropdown onSelect={this.handleSortSelect} className="leaderboard__filter-bar__dropdown ml-1">
            <Dropdown.Toggle>
              <span><i className="fas fa-sort"></i> {SORT_PRETTY_NAME[this.props.filter.sort]}</span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item eventKey="votes">Top</Dropdown.Item>
              <Dropdown.Item eventKey="time">Recent</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div>
          <Dropdown onSelect={this.handleEraSelect} className="leaderboard__filter-bar__dropdown ml-1">
            <Dropdown.Toggle>
              <span><i className="far fa-clock"></i> {ERA_PRETTY_NAME[this.props.filter.era]}</span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item eventKey="day">{ERA_PRETTY_NAME["day"]}</Dropdown.Item>
              <Dropdown.Item eventKey="week">{ERA_PRETTY_NAME["week"]}</Dropdown.Item>
              <Dropdown.Item eventKey="month">{ERA_PRETTY_NAME["month"]}</Dropdown.Item>
              <Dropdown.Item eventKey="year">{ERA_PRETTY_NAME["year"]}</Dropdown.Item>
              <Dropdown.Item eventKey="all">{ERA_PRETTY_NAME["all"]}</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        {!mobileCheck() && <Col className="text-right">
          <div className="leaderboard__filter-bar__refresh text-right" onClick={() => this.props.updateFilter("reload", null)}>
            <i className="fas fa-sync-alt fa-lg pt-2"></i>
          </div>
        </Col>}
        {/* <Col>
          <ButtonGroup toggle className="float-right">
            <ToggleButton className="leaderboard__filter-bar__toggle__left leaderboard__filter-bar__toggle--on" type="radio" name="radio" defaultChecked value="1">
              Posts
            </ToggleButton>
            <ToggleButton className="leaderboard__filter-bar__toggle__left leaderboard__filter-bar__toggle--off" type="radio" name="radio" value="2">
              Users
            </ToggleButton>
          </ButtonGroup>
        </Col> */}
      </Row>
    </Container>
    );
  }
}

export default LeaderboardFilterBar;