import React from "react";
import { FACEBOOK, INSTAGRAM } from "../helpers/constants";

class Footer extends React.Component {
  render() {
    return (
      <div className="pb-1 footer">
        © 2020 All Rights Reserved | <a href= "/terms">Terms of Service</a> | <a href= "/privacy">Privacy</a> | <a href= "/contact">Contact Us</a> | 
        &nbsp;<a target="_blank" href={FACEBOOK}><i class="fab fa-facebook"></i>&nbsp;</a> <a target="_blank" href={INSTAGRAM}><i class="fab fa-instagram"></i></a>
      </div>
    );
  }
}

export default Footer;