import React from "react";
import Button from "react-bootstrap/Button"
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import InputGroup from "react-bootstrap/InputGroup";
import { changeUsername } from '../helpers/auth';


class DefaultUsernameModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      username_invalid_flag: false, 
      change_username_loader: false,
      error: null, 
      username: ""
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    var temp_username_invalid_flag = this.state.username_invalid_flag; 
    if ((event.target.name) === "username") {
      temp_username_invalid_flag = false; 
    }
    this.setState({
      [event.target.name]: event.target.value, 
      username_invalid_flag: temp_username_invalid_flag
    });
  }
  
  handleChangeUsername = async (event) => {
    event.preventDefault();
    this.setState({ error: '' });
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    }
    this.setState({validated: true});
    
    try {
      this.setState({change_username_loader: true});
      await changeUsername(this.state.username);
      this.setState({change_username_loader: false});
      this.props.toggleModal();
      window.location.reload(false);
    } catch (error) {
      this.setState({ 
        username_invalid_flag: true,
        change_username_loader: false
      });
    }
  }

  handleCloseModal = () => {
    var confirmation = window.confirm("Are you sure you want to keep the username @" + this.props.user.username + "? This is your last chance to change it.");
    if (confirmation) {
      this.props.toggleModal();
    }
  }

  render() {
    return (
      <Modal show={this.props.show} onHide={this.handleCloseModal}>
        <Modal.Header className="sign-in__modal__header" closeButton></Modal.Header>
        <div className="pt-4 pb-5 sign-in__email_verificaiton">
          <h3><b>Create Your Username</b></h3> 
          {this.props.user && <p className="px-3">We've temporarily set your username to @{this.props.user.username}. Would you like to change it?</p>}
          <Form noValidate validated={this.state.validated} className="sign-in__form" onSubmit={this.handleChangeUsername} >
            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled tooltip-bottom">Your username is how other Fridge Magnets users will see you.</Tooltip>}>
              <InputGroup className="sign-in__form__input">
                <InputGroup.Prepend className="remove-radius--right">
                  <InputGroup.Text id="inputGroupPrepend">@</InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  className={this.state.username_invalid_flag ? "remove-radius--left is-invalid" : "remove-radius--left"}
                  type="text"
                  name="username"
                  placeholder="New Username"
                  onChange={this.handleChange}
                  value={this.state.username}
                  controlId="validation_username"
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Oops! This username was already taken.
                </Form.Control.Feedback>
              </InputGroup>
            </OverlayTrigger>
            <div className="sign-in__form__submit">
              {this.state.error ? <p className="sign-in__form__error-text">{this.state.error}</p> : null}
              <Button className="sign-in__btn  mt-3" block type="submit">Change Username</Button>
              {this.state.change_username_loader ? 
                <div className="mb-3 mt-2 d-flex justify-content-center ">
                  <div className="spinner-border magnets__post-loader" role="status"></div>
                </div> :
                <Button block className="sign-in__btn sign-in__btn--secondary mb-3" onClick={this.handleCloseModal}>Close</Button>
              }
            </div>
          </Form>
        </div>
    </Modal>
    );
  }
}

export default DefaultUsernameModal;