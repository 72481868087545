import React from "react";
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import {mapScoreToLevel} from '../helpers/functions'
import {HIDE_USER_INFO_WIDTH} from "../helpers/constants"

class FridgeNavbarUserInfo extends React.Component {
  constructor() {
    super();
    this.state = {
      hide_user_info: (window.innerWidth < HIDE_USER_INFO_WIDTH),
    };
    window.addEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    this.setState({
      hide_user_info: (window.innerWidth < HIDE_USER_INFO_WIDTH)
    })
  }

  render() {
    return (
      <Row>
        <div className="navbar__user__avatar__wrapper">
          <img  
            className="navbar__user__avatar"
            src={this.props.avatar_url}
          ></img>
        </div>
        {(!this.state.hide_user_info) && <Col xs={0}>
          <div className="navbar__user__handle">{this.props.fridge_handle}</div>
          <div className="navbar__user__score">
            <i className={"fas fa-circle pr-1 user__level-" + mapScoreToLevel(this.props.score)}></i>
            {this.props.score.toLocaleString()}
          </div>
        </Col>}
      </Row>
    );
  }
}

export default FridgeNavbarUserInfo;