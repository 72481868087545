import React from "react";
import Navbar from "react-bootstrap/Navbar"
import Nav from "react-bootstrap/Nav"
import NavDropdown from "react-bootstrap/NavDropdown"
import Button from "react-bootstrap/Button"
import FridgeNavbarUserInfo from "./FridgeNavbarUserInfo"
import FridgeNavbarMyAccountModal from "./FridgeNavbarMyAccountModal"
import { signOut } from '../helpers/auth';
import Container from "react-bootstrap/Container";
import {GET_REAL_MAGNETS, INSTAGRAM} from '../helpers/constants'

class FridgeNavbar extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = { 
      accountModalShow: false
    };
    this.toggleAccountModal = this.toggleAccountModal.bind(this);
  }

  toggleAccountModal() {
    this.setState({
      accountModalShow: !this.state.accountModalShow
    });
  }
  render() {
    return (
      <Container fluid className="fridge-navbar pt-1">
        <Navbar className="justify-content-between">
          <Navbar.Brand pr-0>
            <img
              src={require("../img/logo-sm.png")}
              height="30"
              className="d-inline-block align-top"
            />
          </Navbar.Brand>
          {this.props.mobile_width && <Nav 
            className="navbar__view-selector" 
            activeKey={this.props.leaderboard_active ? "leaderboard": "magnets"} 
            onSelect={this.props.handleNavbarSelect}
          >
            <Nav.Item>
              <Nav.Link eventKey="leaderboard">Posts</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="magnets">Magnets</Nav.Link>
            </Nav.Item>
          </Nav>}
          {this.props.logged_in && this.props.user ? 
            <NavDropdown alignRight id="nav-dropdown" title={
                <FridgeNavbarUserInfo 
                  fridge_handle ={this.props.user.username}
                  score = {this.props.user.score}
                  avatar_url = {this.props.user.avatar}
                />
              }>
              <NavDropdown.Item onSelect={this.toggleAccountModal}>My Account</NavDropdown.Item>
              <NavDropdown.Item eventKey="navbar--leaderboard-store" target="_blank" href={GET_REAL_MAGNETS}>Get Real Magnets</NavDropdown.Item>
              <NavDropdown.Item eventKey="navbar--leaderboard-store" target="_blank" href={INSTAGRAM}><i class="fab fa-instagram"></i> Follow frij.io</NavDropdown.Item>
              <NavDropdown.Item onSelect={this.props.showWelcomeModal}>Help</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item eventKey="navbar--leaderboard-sign-out" onClick={signOut}>Sign Out</NavDropdown.Item>
            </NavDropdown>
          : <Button className="navbar__sign-in-button" onClick={this.props.signIn}>Sign In</Button>}
        </Navbar>
        {this.props.logged_in && this.props.user && <FridgeNavbarMyAccountModal 
          show={this.state.accountModalShow} 
          changeAvatar={this.props.changeAvatar} 
          user={this.props.user} 
          toggleAccountModal={this.toggleAccountModal}
        />} 
      </Container>    
    );
  }
}

export default FridgeNavbar;