import React from "react";
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Form from "react-bootstrap/Form"
import Button from "react-bootstrap/Button"
import InputGroup from "react-bootstrap/InputGroup"
import {auth} from '../services/firebase';
import FullPageLoader from "./FullPageLoader";

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      new_password: "",
      loading: true,
      pw_field: true,
      message: ""
    };
    auth().verifyPasswordResetCode(this.props.actionCode).then((account_email) => {
      this.setState({
        loading: false,
        message: "Reset password for " + account_email
      });
    }).catch((error) => {
      this.setState({
        loading: false,
        pw_field: false,
        message: "Invalid reset code. Please try reseting your password again."
      });
    });
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value, 
    });
  }

  handleResetPassword = () => {
    this.setState({
      loading: true,
    });
    auth().confirmPasswordReset(this.props.actionCode, this.state.new_password).then((resp) => {
      this.setState({
        pw_field: false,
        loading: false,
        message: "Your password has been reset!"
      });
    }).catch((error) => {
      this.setState({
        loading: false,
        message: "Oops, the reset code might have expired or your new password is too weak."
      });
    });
  }

  returnToMain = async (event) => {
    window.location.href = '/';
  }
  
  render() {
    return (
      <div>
        { this.state.loading ?
          <FullPageLoader /> :
          <Container className="container__reset_password px-5">
            <Row>
              <Col md={{ span: 8, offset: 2 }}>
                <div>
                  <h1 className="mt-5">Password Reset:</h1>
                  <p>{this.state.message}</p>
                    <hr></hr>
                    { this.state.pw_field ? 
                      <span>
                        <Form onSubmit={this.handleResetPassword}>
                          <p><b>New Password:</b></p>
                          <InputGroup className="sign-in__form__input">
                            <Form.Control
                              type="password"
                              name="new_password"
                              placeholder="Password"
                              onChange={this.handleChange}
                              value={this.state.new_password}
                              required
                            />
                            <Form.Control.Feedback type="invalid">
                              Oops! This username isn't valid.
                            </Form.Control.Feedback>
                          </InputGroup>
                          <Button className="mt-3 mb-5 px-4" type="submit">Reset Password</Button>
                        </Form>
                      </span> :
                      <Button className="mt-2 mb-5 px-4" onClick={this.returnToMain}>Back to Main Page</Button>
                  }
                </div>
              </Col>
            </Row>
          </Container>
        }
      </div>
    );
  }
}

export default ResetPassword;