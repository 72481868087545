import React from "react";
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

class Sentence extends React.Component {
  render() {
    const sentenceDivs = [];
    if (this.props.sentence) {
      const sentenceLength = this.props.sentence.length;
      for (var i = 0; i < sentenceLength; i++) {
        var wordClass = "";
        switch(i) {
          case 0:
            wordClass = "magnets__sentence__word--first col-auto";
            if (sentenceLength - 1 === 0) {
              wordClass = "magnets__sentence__word--frist-last col-auto";
            } 
            break;
          case sentenceLength - 1:
            wordClass = "magnets__sentence__word--last col-auto";
            break;
          default:
            wordClass = "magnets__sentence__word--middle col-auto";
        }
        if (this.props.static){
          sentenceDivs.push(
            <Col key={this.props.sentence[i] + "-" + i} className={wordClass}>
              {this.props.sentence[i]}
            </Col>
          );
        } else {
          sentenceDivs.push(
            <Col 
              key={this.props.sentence[i] + "-" + i} 
              onMouseDown={this.props.removeWordFromSentence.bind(this, i)} 
              className={wordClass + " cursor_pointer"} 
              ref={this.props.refs[i]}
            >
              {this.props.sentence[i]}
            </Col>
          );
        }
      }
    }
    return (
      <Row className="magnets__sentence">
        {sentenceDivs}
        {sentenceDivs.length === 0 && <span className="magnets__sentence__composer-placehoder">drop words here...</span>}
        <Col className="col-xs"></Col>
      </Row>
    );
  }
}

export default Sentence;