import React from 'react';
import omit from 'lodash/omit';

import {SingleDatePicker} from 'react-dates';

class LeaderboardDatePicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      focused: props.autoFocus,
      date: props.initialDate,
    };

    this.onDateChange = this.onDateChange.bind(this);
    this.onFocusChange = this.onFocusChange.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.initialDate !== prevProps.initialDate && this.props.initialDate !== this.state.date) {
      this.setState({ date: this.props.date });
    }
  }

  onDateChange(date) {
    this.setState({ date });
    this.props.update(date);
  }

  onFocusChange({ focused }) {
    this.setState({ focused });
  }

  render() {
    const { focused, date } = this.state;

    // autoFocus and initialDate are helper props for the example wrapper but are not
    // props on the SingleDatePicker itself and thus, have to be omitted.
    const props = omit(this.props, [
      'autoFocus',
    ]);

    return (
      <SingleDatePicker
        {...props}
        id="date_input"
        date={date}
        focused={focused}
        onDateChange={this.onDateChange}
        onFocusChange={this.onFocusChange}
        className="leaderboard__date-picker" 
      />
    );
  }
}

export default LeaderboardDatePicker;